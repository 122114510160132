<template>
    <div class="mov_estado">{{mov_estado_descripcion}}</div>
    <div class="row m0 p0">
        <div class="col m8 card-panel m0 p0" style="height:250px">
            <div class="row m0 p0">
                <div class="col m2">
                    <label>Ciclo</label>
                    <select :disabled="!esEditable" class="browser-default" @change="cambiarCiclo($event)" v-model="mov_codcil">
                        <option v-for="ciclo in ciclos" v-bind:key="ciclo" v-bind:value="ciclo.cil_codigo">{{ciclo.cic_nombre}} - {{ciclo.cil_anio}} {{ ciclo.cil_solvente ? '(Solvente)':'' }}</option>
                    </select>
                </div>
                <div class="col m2">
                    <label>Fecha Facturacion</label>
                    <datepicker v-model="mov_fecha" :disabled="mov_tipo_pago != 'D' || !esEditable" inputFormat="dd/MM/yyyy" />
                </div>
                <div class="col m2">
                    <label>Fecha Registro</label>
                    <input type="text" v-model="mov_fecha_registro" disabled>
                </div>
                <div class="col m2" v-show="mov_codigo > 0">
                    <label>Usuario</label>
                    <input type="text" v-model="mov_usuario" disabled>
                </div>
                <div class="col m4" v-if="mov_codigo > 0 && mov_tipo != 'R'">
                    <label>Factura Electrónica <span v-show="mov_mh_procesada">(Registrada)</span> <span v-show="!mov_mh_procesada">(Pendiente)</span></label>
                    <br>
                    <span class="badge new green darken-2" data-badge-caption="" v-show="mov_mh_procesada" style="font-size: 12px; float: left; margin: 0px">{{ mov_mh_codigo_generacion }}</span>
                    <span class="badge new red darken-2" data-badge-caption="" v-show="!mov_mh_procesada" style="font-size: 12px; float: left; margin: 0px">{{ mov_mh_codigo_generacion }}</span>
                </div>
            </div>

            <div class="row m0 p0">
                <div class="col m2">
                    <label>Tipo</label>
                    <select :disabled="mov_codigo != 0" class="browser-default" v-model="mov_tipo" @change="getNumeroRecibo(true);setMovTipoPago()">
                        <option v-for="tipo in tipo_documento" :disabled="tipo.tdo_estado == 'I'" v-bind:key="tipo" v-bind:value="tipo.tdo_sigla">{{tipo.tdo_nombre}}</option>
                    </select>
                    <label v-if="mov_tipo == 'R'"><input type="checkbox" class="filled-in" v-model="modo_actualizacion"><span style="font-size:10px">ACTUALIZACIÓN</span></label>
                </div>
                <div class="col m2">
                    <label>Serie</label>
                    <input type="text" v-model="mov_serie_documento" disabled>
                </div>
                <div class="col m2">
                    <label>Numero</label>
                    <input type="text" v-model="mov_recibo" disabled>
                </div>
                <div class="col m2">
                    <label>Banco</label>
                    <select :disabled="!esEditable" class="browser-default" v-model="mov_codban">
                        <template v-for="banco in bancos" v-bind:key="banco">
                            <option v-if="banco.ban_codigo == 0" :value="banco.ban_codigo">{{ banco.ban_nombre }}</option>
                            <!-- <option v-if="banco.ban_codigo == 0 && mov_tipo_pago != 'D'" :value="banco.ban_codigo">{{ banco.ban_nombre }}</option> -->
                            <option v-if="banco.ban_codigo != 0 && mov_tipo_pago == 'D'" :value="banco.ban_codigo">{{ banco.ban_nombre }}</option>
                            <!-- <option v-bind:value="banco.ban_codigo" v-if="(banco.ban_codigo == 0 && mov_tipo_pago == 'D') || (banco.ban_codigo != 0 && mov_tipo_pago != 'D')">{{banco.ban_nombre}}</option> -->
                        </template>
                    </select>
                </div>               

                <div class="col m4">
                    <label>Cliente</label>   
                    <a href="#!" v-if="mov_codigo == 0" class="right" @click="searchClienteModal()"><i class="material-icons" style="font-size:16px">search</i></a>               
                    <select class="browser-default" disabled v-model="mov_ClientCardCode" @change="setCliente" id="mov_ClientCardCode">
                        <option value="">NINGUNO</option>
                        <option v-for="cliente in clientes" v-bind:key="cliente" v-bind:value="cliente.cardCode">{{cliente.cardName}}</option>
                    </select>
                    <small class="right"><b v-show="((cliente.vatIdUnCmp || '') != '')">NIT:</b> {{cliente.vatIdUnCmp}} <b v-show="((cliente.addID || '') != '')">NRC:</b> {{cliente.addID}} <b class="red-text darken-4">{{(cliente.wtLiable == 'Y' ? 'CONTRIBUYENTE':'')}}</b></small>
                </div>
            </div>

            <div class="row m0 p0">
                <div class="col m2">
                    <label>Forma de Pago</label>
                    <select :disabled="mov_codigo != 0" class="browser-default" v-model="mov_tipo_pago" @change="setMovFormaPago()">
                        <!--col_mdp_metodo_de_pago-->
                        <option v-for="forma in formas_pago" :disabled="(forma.mdp_estado == 'I' || mov_tipo_pago_type_disabled(forma.mdp_sigla))" v-bind:data-mdp_estado = "forma.mdp_estado" v-bind:key="forma" v-bind:value="forma.mdp_sigla">{{forma.mdp_nombre}}</option>
                    </select>
                    <label v-if="mov_tipo_pago == 'D'"><input type="checkbox" v-model="mov_comprobante_fisico" class="filled-in"><span style="font-size:12px">Comp. Físico</span></label>
                </div>                
                <div class="col m1" v-show="(mov_tipo_pago == 'T')">
                    <label># Tarj.</label>
                    <InputMask :disabled="mov_codigo != 0" type="text" maxlength="4" minlength="4" v-model="mov_tarjeta" mask="9999"/>
                </div>
                <div class="col m1" v-show="(mov_tipo_pago == 'T')">
                    <label>XX/XX</label>
                    <InputMask :disabled="mov_codigo != 0" type="text" v-model="mov_fecha_tarjeta" mask="99/99"/>
                </div>
                <div class="col m1" v-show="(mov_tipo_pago == 'T')">
                    <label>Voucher</label>
                    <input :disabled="mov_codigo != 0" type="text" v-model="mov_voucher">
                </div>
                <div class="col m1 p0" v-show="(mov_tipo_pago == 'T')">
                    <label><input :disabled="mov_codigo != 0" type="radio" class="browser-default" name="mov_pos" value="P" v-model="mov_pos" /><span style="font-size:12px; padding: 0px 10px 0px 25px; height:0px">Prome.</span></label><br/>
                    <label><input :disabled="mov_codigo != 0" type="radio" class="browser-default" name="mov_pos" value="C" v-model="mov_pos" /><span style="font-size:12px; padding: 0px 10px 0px 25px; height:0px">Credo.</span></label><br/>
                    <label><input :disabled="mov_codigo != 0" type="radio" class="browser-default" name="mov_pos" value="A" v-model="mov_pos" /><span style="font-size:12px; padding: 0px 10px 0px 25px; height:0px">Agríc.</span></label><br/>
                </div>
                <div class="col m2" v-show="(mov_tipo_pago == 'C')">
                    <label>Cheque</label>
                    <input :disabled="mov_codigo != 0" type="text" v-model="mov_cheque">
                </div>
                <div class="col m2">
                    <label>Método de Pago</label>
                    <!--col_fdp_formas_de_pago-->
                    <select :disabled="mov_codigo != 0" class="browser-default" v-model="mov_forma_pago">
                        <option v-for="metodo in metodos_pago" :disabled="(metodo.fdp_estado == 'I' || mov_forma_pago_type_disabled(metodo.fdp_sigla))" v-bind:key="metodo" v-bind:value="metodo.fdp_sigla">{{metodo.fdp_nombre}}</option>
                    </select>
                </div>
                <div class="col m4">
                    <label>Estudiante</label>
                    <a v-if="mov_codigo == 0" href="#!" @click="$parent.buscarEstudiante()"><i class="material-icons right" style="font-size:16px">search</i></a>
                    <input type="text" disabled v-bind:value="per_apellidos_nombres">
                    <small class="right">{{per_carnet}}</small>
                </div>
            </div>

            <div class="row m0 p0">
                <div class="col m12">
                    <label>Concepto</label>
                    <span data-badge-caption="" class="badge new green darken-1 btn" v-show="devoluciones.findIndex(x=>x.cil_codigo == mov_codcil) != -1">
                        <a href="#!" @click="current_tab = 5" style="color: white">Existe devolución este ciclo <i class="material-icons right">info</i></a>
                    </span>
                    <textarea :disabled="mov_codigo != 0" v-model="mov_descripcion"></textarea>
                    <span class="helper-text">{{$validation(validations, "mov_descripcion")}}</span>
                </div>
            </div>
        </div>

        <div class="col m4" v-show="mov_codper > 0">
            <ul class="m0 p0">
                <li class="tab m0 p0" style="display: inline-block;zoom:1; border-radius:2px 2px 0px 0px" v-bind:class="{'blue-ugb': current_tab == 1}"><a v-bind:class="{'white-text': current_tab == 1}" style="font-size:12px; margin:2px; padding:2px; line-height:auto" href="#!" @click="current_tab = 1">GENERALIDADES</a></li>
                <li class="tab m0 p0" style="display: inline-block;zoom:1; border-radius:2px 2px 0px 0px;" v-bind:class="{'blue-ugb': current_tab == 2}"><a v-bind:class="{'white-text': current_tab == 2}" style="font-size:12px; margin:2px; padding:2px; line-height:auto" @click="getMovimientosEstudiantes(false);current_tab = 2;" @dblclick="getMovimientosEstudiantes(true);current_tab = 2;" href="#movimientos">PAGOS</a></li>
                <li class="tab m0 p0" style="display: inline-block;zoom:1; border-radius:2px 2px 0px 0px;" v-bind:class="{'blue-ugb': current_tab == 3}"><a v-bind:class="{'white-text': current_tab == 3}" style="font-size:12px; margin:2px; padding:2px; line-height:auto" href="#!" @click="getSolvencia(false);current_tab = 3" @dblclick="getSolvencia(true);current_tab = 3">SOLVENCIA</a></li>
                <li class="tab m0 p0" style="display: inline-block;zoom:1; border-radius:2px 2px 0px 0px;" v-bind:class="{'blue-ugb': current_tab == 4}"><a v-bind:class="{'white-text': current_tab == 4}" style="font-size:12px; margin:2px; padding:2px; line-height:auto" href="#!" @click="getDescuentosExoneraciones(false);current_tab = 4" @dblclick="getDescuentosExoneraciones(true);current_tab = 4;">EXO-DESC</a></li>
                <!-- <li class="tab m0 p0" v-show="mov_codigo > 0" style="display: inline-block;zoom:1;" v-bind:class="{'blue-ugb': current_tab == 5}"><a v-bind:class="{'white-text': current_tab == 5}" style="font-size:12px; margin:2px; padding:2px; line-height:auto" href="#!" @click="getNotas(false);current_tab = 5" @dblclick="getNotas(true);current_tab = 5;">NOTAS</a></li> -->
                <li class="tab m0 p0" v-if="devoluciones.length > 0" style="display: inline-block; zoom:1" v-bind:class="{'blue-ugb': current_tab == 5}"><a v-bind:class="{'white-text': current_tab == 5}" style="font-size:12px; margin:2px; padding:2px; line-height:auto" href="#!" @click="current_tab = 5">DEVOLUCIÓNES</a></li>
            </ul>
            <div style="height:200px; overflow:auto">

                <!--DATOS DEL ESTUDIANTE-->
                <div v-show="current_tab == 1">
                    <table class="table tbl-condensed bordered striped" style="font-size:11px">
                        <caption class="blue-ugb white-text">DATOS DEL ESTUDIANTE</caption>
                        <tbody>
                            <tr>
                                <td colspan="3">
                                    <a v-bind:href="'/Estudiante/' + estudiante.per_codigo" target="_blank">
                                        {{estudiante.per_carnet}} - {{estudiante.per_apellidos_nombres}}
                                    </a>
                                </td>
                            </tr>
                            <tr v-show="estudiante.car_codigo > 0">
                                <td colspan="3">{{estudiante.car_nombre}} - {{estudiante.pla_nombre}}</td>
                            </tr>
                            <tr v-bind:class="{'red lighten-3': (per_estado == 'Egresado')}">
                                <td style="width:33%">ESTADO: {{per_estado}}</td>
                                <td style="width:33%">TIPO: {{per_tipo}}</td>
                                <td style="width:33%">MODALIDAD: {{car_virtual}}</td>
                            </tr>
                            <tr v-if="estudiante.bec_porcentaje > 0">
                                <td colspan="3">{{estudiante.bec_nombre}} ({{estudiante.bec_porcentaje}}%)</td>
                            </tr>
                            <tr>
                                <td class="center">
                                    <a class="btn green darken-3 btn-small" style="font-size:10px; margin:0px" @click="modalOpen('/Reportes/EstadoCuenta/' + estudiante.per_carnet)" href="#!">ESTADO DE CUENTA</a>
                                </td>
                                <td class="center">
                                    <a class="btn green darken-3 btn-small" style="font-size:10px; margin:0px" @click="modalOpen('/Reportes/Boleta/' + estudiante.per_carnet + '/' + estudiante.car_virtual + '/' + estudiante.car_codigo)" href="#!">BOLETA DE NOTAS</a>
                                </td>
                                <td class="center">
                                    <a class="btn green darken-3 btn-small" v-if="estudiante.fel_tipo == 'P'" style="font-size:10px; margin:0px" @click="modalOpen('/Reportes/BoletaPre/' + estudiante.per_carnet + '/' + mov_codcil)" href="#!">BOLETA PRE</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span v-if="estudiante.fel_tipo != 'P'" class="badge red darken-4 white-text" v-show="materias_inscritas.length == 0" style="width:100%">SIN MATERIAS INSCRITAS</span>
                    <span v-if="estudiante.fel_tipo == 'P'" style="font-size: 12px">
                        <ul class="collection">
                            <li class="collection-item green lighten-5">
                                {{estudiante.pre_nombre}}
                                <br>
                                <small>{{proceso_graduacion.grh_descripcion}}</small>
                            </li>
                            <li class="collection-item">
                                <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                                    <thead>
                                        <tr>
                                            <th style="width: 5%">#</th>
                                            <th style="width: 55%">Arancel</th>
                                            <th style="width: 20%">Cuota</th>
                                            <th style="width: 20%">Fecha</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(arancel, index) in proceso_graduacion.aranceles" :key="arancel">
                                            <tr v-bind:class="{'red lighten-5': !arancel.tmo_cancelado, 'green lighten-5': arancel.tmo_cancelado}">
                                                <td class="center">{{index + 1}}</td>
                                                <td>{{arancel.tmo_descripcion}}</td>
                                                <td class="center">${{arancel.tmo_cuota.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                                <td class="center">{{arancel.fel_fecha}}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </li>
                        </ul>
                    </span>

                    <div class="row m0 p0" v-if="fechas_posgrado.length > 0">
                        <table class="table tbl-condensed tbl-bordered" style="font-size: 12px">
                            <thead>
                                <tr>
                                    <th style="width: 5%">#</th>
                                    <th style="width: 55%">Arancel</th>
                                    <th style="width: 20%">Cuota</th>
                                    <th style="width: 20%">Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(arancel, index) in fechas_posgrado" :key="arancel">
                                    <td class="center">{{index + 1}}</td>
                                    <td>{{arancel.tmo_descripcion}}</td>
                                    <td class="center">${{arancel.fgr_valor.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                    <td class="center">{{arancel.fgr_fecha_limite}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <table class="table tbl-condensed bordered striped" style="font-size:11px" v-show="materias_inscritas.filter(x=>x.mai_estado == 'I').length > 0">
                        <tbody>
                            <tr class="blue-ugb white-text">
                                <td class="center">MATERIA</td>
                                <td class="center">LAB.</td>
                                <td class="center">HC</td>
                                <td class="center">FIN.</td>
                            </tr>
                            <template v-for="materia in materias_inscritas" v-bind:key="materia">
                                <tr v-if="materia.mai_estado == 'I'">
                                    <td>{{materia.mat_codigo}} - {{materia.mat_nombre}} ({{(materia.mai_estado == 'I' ? 'INSCRITA':'RETIRADA')}})</td>
                                    <td class="center">{{(materia.plm_laboratorio == 'S' ? 'SI':'NO')}}</td>
                                    <td class="center">{{(materia.plm_horas_clinica > 0 ? 'SI':'NO')}}</td>
                                    <td class="center">{{(materia.mai_financiada == 'S' ? 'SI':'NO')}}</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <table style="font-size:12px" class="table tbl-thinner bordered stripped" v-show="aranceles_estudiante.length > 0">
                        <thead>
                            <tr>
                                <th style="width:30%">Cant. Mat.</th>
                                <th style="width:30%">Valor Base</th>
                                <th style="width:30%">Valor - Beca</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="arancel in aranceles_estudiante" v-bind:key="arancel">
                                <td class="center">{{arancel.cantidad}}</td>
                                <td class="center">${{arancel.valorBase.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                <td class="center">${{arancel.valor.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                            </tr>
                            <tr v-if="aranceles_estudiante.length > 0">
                                <th colspan="3" class="center">MATRICULA: ${{aranceles_estudiante[0].matricula.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!--MOVIMIENTOS-->
                <div v-show="current_tab == 2">
                    <table class="table tbl-condensed bordered striped" v-show="movimientos_estudiante.length > 0" style="font-size:11px; width:500px">
                        <caption class="blue-ugb white-text">ÚLTIMOS PAGOS</caption>
                        <tbody>
                            <tr v-for="movimiento in movimientos_estudiante" v-bind:key="movimiento">
                                <td class="center" :title="movimiento.mov_recibo">{{$spliceString(movimiento.mov_recibo, 0, 8)}}</td>
                                <td style="width:20%" class="center">{{movimiento.mov_fecha}}</td>
                                <td style="width:40%">{{movimiento.tmo_descripcion}}</td>
                                <td>{{movimiento.mes_nombre}}</td>
                                <td style="width:30%" class="center">{{movimiento.mat_codigo}} - {{movimiento.mat_nombre}}</td>
                                <td style="width:10%; text-align:right">${{(movimiento.dmo_valor + movimiento.dmo_iva).toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!--ESTADO DEL ESTUDIANTE-->
                <div  v-show="current_tab == 3">
                    <table class="table tbl-thinner tbl-condensed bordered striped" style="font-size: 12px">
                        <caption class="blue-ugb white-text">HISTÓRICO SOLVENCIA</caption>
                        <tbody>
                            <tr v-for="solvencia in historial_solvencia" :key="solvencia" v-bind:class="{'red lighten-4': !solvencia.cil_solvente, 'green lighten-4': solvencia.cil_solvente }">
                                <td class="center">Ciclo {{ solvencia.cil_codcic }} - {{ solvencia.cil_anio }}</td>
                                <td class="center">
                                    <span v-show="solvencia.cil_vigente_portal == 'N'">
                                        {{ solvencia.cil_solvente ? "Solvente":"Insolvente" }}
                                    </span>
                                    <span v-show="solvencia.cil_vigente_portal == 'S'">
                                        Pendiente
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="card-panel blue-ugb white-text" style="width: 100%; font-size: 12px">
                        Importante: No se encuentra disponible el historico de solvencia para ciclos anteriores al 1 - 2020.
                    </div>
                    <!-- <table class="table tbl-thinner bordered striped" style="font-size:12px" v-if="solvencia.excedente != undefined">
                        <thead>
                            <th>Arancel</th>
                            <th>Monto</th>
                            <th>Solvente</th>
                        </thead>
                        <tbody>
                            <tr v-for="arancel in solvencia.detalle" v-bind:key="arancel">
                                <td class="center">{{arancel.arancelDescripcion}}</td>
                                <td class="center">${{arancel.monto.toLocaleString(undefined, { minimumFractionDigits: 2 })}}</td>
                                <td class="center"><label><input type="checkbox" disabled class="filled-in" :checked="arancel.solvente"><span></span></label></td>
                            </tr>
                        </tbody>
                    </table> -->
                </div>

                <!--EXONERACIONES Y DESCUENTOS-->
                <div  v-show="current_tab == 4">
                    <table class="tbl-thinner table bordered striped" style="font-size:12px" v-show="exoneraciones.length > 0">
                        <caption class="blue-ugb white-text">EXONERACIONES</caption>
                        <tbody>
                            <tr v-for="exoneracion in exoneraciones" v-bind:key="exoneracion">
                                <td style="width:40%">{{exoneracion.tmo_descripcion}}</td>
                                <td>{{exoneracion.exo_acuerdo}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="tbl-thinner table bordered striped" style="font-size:12px" v-show="descuentos.length > 0">
                        <caption class="blue-ugb white-text">DESCUENTOS</caption>
                        <tbody>
                            <tr v-for="descuento in descuentos" v-bind:key="descuento">
                                <td style="width:80%">{{descuento.tmo_descripcion}}</td>
                                <td style="display:none">{{descuento.bec_nombre}}</td>
                                <td class="center" style="width:20%">{{descuento.dap_porcentaje}}%</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="tbl-thinner table bordered striped" style="font-size:12px" v-show="descuentos_fijos.length > 0">
                        <caption class="blue-ugb white-text">DESCUENTOS FIJOS</caption>
                        <tbody>
                            <tr v-for="descuento in descuentos_fijos" v-bind:key="descuento">
                                <td style="width:30%">{{descuento.tmo_descripcion}}</td>
                                <td class="center" style="width:10%">${{descuento.dfc_monto.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2  })}}</td>
                                <td style="width:60%">{{descuento.dfc_comentario}}%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!--NOTAS-->
                <div  v-show="current_tab == 5">
                    <!-- <table class="table tbl-thinner tbl-bordered" style="font-size:12px">
                        <thead>
                            <th>Fecha</th>
                            <th>Usuario</th>
                            <th>Adjunto</th>
                            <th>Nota</th>
                            <th></th>
                        </thead>
                        <tbody>
                            <template v-for="(nota, index) in notas" :key="nota">
                                <tr v-bind:class="{'grey lighten-2': index == nota_index}">
                                    <td class="center" style="width: ">{{nota.nof_fecha}}</td>
                                    <td class="center">{{nota.nof_usuario}}</td>
                                    <td class="center">
                                        <a href="#!" v-show="nota.nof_adjunto != null" v-on:click="getNotaAdjunto(nota.nof_codigo)"><i class="material-icons">folder_open</i></a>
                                    </td>
                                    <td class="center">
                                        <a href="#!" v-on:click="abrirNota(index)">
                                            <i class="material-icons" v-show="index != nota_index">arrow_drop_down</i>
                                            <i class="material-icons" v-show="index == nota_index">arrow_drop_up</i>
                                        </a>
                                    </td>
                                    <td class="center"><a href="#!" v-on:click="eliminarNota(nof_codigo)"><i class="material-icons">delete</i></a></td>
                                </tr>
                                <tr v-show="index == nota_index">
                                    <td colspan="5">
                                        <ul class="collection m0 p0">
                                            <li class="collection-item">
                                                <blockquote style="text-align:justify">{{nota.nof_nota}}</blockquote>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table> -->

                    <table class="table tbl-condensed tbl-bordered" style="font-size: 12px; width: 100%">
                        <thead>
                            <tr>
                                <th style="width: 20%">Ciclo</th>
                                <th style="width: 20%">Tipo</th>
                                <th style="width: 20%"># Doc.</th>
                                <th style="width: 15%">Fecha</th>
                                <th style="width: 10%">Usuario</th>
                                <th style="width: 10%">Ver</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(devolucion, index) in devoluciones" :key="devolucion">
                                <tr v-bind:class="{'grey lighten-3': index == index_devolucion}">
                                    <td>{{devolucion.cic_nombre}} - {{devolucion.cil_anio}}</td>
                                    <td>{{devolucion.dee_tipo}}</td>
                                    <td>{{devolucion.dee_numero_documento}}</td>
                                    <td>{{devolucion.dee_fecha}}</td>
                                    <td>{{devolucion.dee_usuario}}</td>
                                    <td class="center">
                                        <a href="#!" v-show="index != index_devolucion" @click="index_devolucion = index"><i class="material-icons">arrow_drop_down</i></a>
                                        <a href="#!" v-show="index == index_devolucion" @click="index_devolucion = -1"><i class="material-icons">arrow_drop_up</i></a>
                                    </td>
                                </tr>
                                <tr v-show="index == index_devolucion">
                                    <td colspan="6">
                                        <div class="card-panel m0">
                                            <span class="badge new blue-ugb" v-bind:data-badge-caption="('(' + devolucion.dee_descuento + '%)')">{{devolucion.dee_tipo}}</span>
                                            <blockquote>{{devolucion.dee_observacion}}</blockquote>
                                            <ul class="collection">
                                                <li class="collection-item" v-for="detalle in devolucion.detalle" :key="detalle">
                                                    <span class="badge new green darken-4" v-bind:data-badge-caption="'$'+detalle.dde_valor.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2  })"># Doc: {{detalle.mov_recibo}}
                                                        | Tipo: {{(tipo_documento.find(x=>x.tdo_sigla == detalle.mov_tipo) == undefined ? '':tipo_documento.find(x=>x.tdo_sigla == detalle.mov_tipo).tdo_nombre)}} |
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>                                        
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="row m0 p0">        
        <div class="col m12 card-panel center m0 p0" style="background-color: #1c21d1;">
            <div style="margin:5px">
                <button type="button" @click="resetForm(true, 'N')" class="btn green darken-2 btn-small" style="margin-right:10px">NUEVO <i class="material-icons right">add_circle</i></button>
                <button type="button" class="btn orange darken-4 btn-small" @click="modalAutorizar()" style="margin-right:10px" v-show="esAnulable">ANULAR <i class="material-icons right">cancel</i></button>
                <button type="button" class="btn blue btn-small" style="margin-right:10px" @click="guardar" v-show="mov_codigo == 0 && detalle_movimiento.length > 0">GUARDAR <i class="material-icons right">save</i></button>

                <button type="button" class="btn blue btn-small" style="margin-right:10px" @click="actualizar()" v-show="esActualizable">ACTUALIZAR <i class="material-icons right">update</i></button>

                <button type="button" class="btn teal darken-2 btn-small" @click="imprimir" style="margin-right:10px" v-show="imprimible()">IMPRIMIR <i class="material-icons right">print</i></button>
                <button type="button" class="btn teal darken-2 btn-small" @click="imprimirFE()" style="margin-right:10px" v-show="detalle_movimiento.length > 0 && mov_tipo != 'R' && mov_codigo > 0 && (mov_mh_codigo_generacion || '') != ''">IMPRIMIR FE <i class="material-icons right">print</i></button>
                <button type="button" class="btn red darken-3 btn-small" @click="eliminar" v-show="mov_tipo == 'R' && mov_codigo > 0 && mov_estado == 'R'" style="margin-right:10px">ELIMINAR <i class="material-icons right">delete</i></button>
                <button type="button" @click="cerrarModal()" class="btn red darken-3 btn-small modal-close" style="margin-right:10px">CERRAR <i class="material-icons right">close</i></button>
            </div>
        </div>
        <div class="right grey lighten-4" style="padding:0px 2px 2px 2px" v-show="mov_codigo > 0 && detalle_movimiento.length > 0 && mov_impreso == 'N' && mov_tipo != 'R' && mov_estado != 'A'">
            <small>OPCIONES DE IMPRESIÓN &nbsp;</small>
            <label><input type="checkbox" v-model="factura_concepto" id="chk_factura_concepto"><span style="font-size:10px">Concepto </span></label> &nbsp;
            <label><input type="checkbox" v-model="factura_version" id="chk_factura_version"><span style="font-size:10px">Ver. 2 </span></label>
        </div>
    </div>
    <div class="card-panel m0" v-if="mov_serie_documento != '' && mov_recibo != '' && mov_codper != 0 && mov_codcil != 0">
        <div class="row m0 p0" v-if="mov_codigo == 0 && detalle_movimiento.length <= 15">
            <form @submit.prevent="agregarDetalle()">
                <div class="col m12">
                    <div class="col m4">
                        <label>Movimiento</label> <a href="#!" class="right" @click="buscarArancel()"><i class="material-icons" style="font-size:15px">search</i></a>
                        <select class="browser-default" v-model="dmo_codtmo" @change="getCostoArancel()">
                            <option value="0">Seleccione</option>                            
                            <option v-show="arancel_visible(arancel)" v-for="arancel in aranceles" v-bind:key="arancel" v-bind:value="arancel.tmo_codigo">{{arancel.tmo_descripcion}}</option>
                        </select>
                    </div>
                    <div class="col m1" v-show="tmo_cantidad && materias_inscritas.length == 0 && estudiante.per_tipo == 'U' && (estudiante.fel_tipo == 'N')">
                        <label>Cant. Mat.</label>
                        <input type="number" @change="cambiarCantidadMaterias" class="text-right" v-model="cantidad_materias" min="1" max="5">
                    </div>
                    <div class="col m1" v-show="tmo_talonarios">
                        <label>Mes</label>
                        <select class="browser-default" v-model="dmo_mes">
                            <option v-for="mes in meses" v-bind:key="mes" v-bind:value="mes.mes_codigo">{{mes.mes_nombre}}</option>
                        </select>
                    </div>
                    <!-- <div class="col m3" v-if="estudiante.fel_tipo == 'P' && tipo_mov_arancel">
                        <label>Módulo</label>
                        <select class="browser-default" v-model="dmo_codmpr">
                            <option value="0">Seleccione</option>
                            <option v-for="modulo in modulos_pre" v-bind:key="modulo" v-bind:value="modulo.mpr_codigo">{{modulo.mpr_nombre}}</option>             
                        </select>
                    </div> -->
                    <div class="col m3" v-if="tmo_afecta_materia && dmo_codtmo == 75">
                        <label>Materia Autofinanciada</label>
                        <select class="browser-default" v-model="dmo_codmat" @change="setValorAutofinanciada()">
                            <option value="">Seleccione</option>
                            <option v-for="materia in materias_autofinanciadas" v-bind:key="materia" v-bind:value="materia.codigoMateria">{{materia.codigoMateria}} - {{materia.materia}}</option>
                        </select>
                    </div>
                    <div class="col m3" v-if="tmo_afecta_materia && (dmo_codtmo != 75 && dmo_codtmo != 2713 && dmo_codtmo != 2710)">
                        <label>Materia</label>
                        <select class="browser-default" v-model="dmo_codmat">
                            <option value="">Seleccione</option>
                            <option v-for="materia in materias_inscritas" v-bind:key="materia" v-bind:value="materia.mat_codigo">{{materia.mat_codigo}} - {{materia.mat_nombre}} (Ciclo {{materia.plm_ciclo}})</option>
                        </select>
                    </div>
                    <div class="col m3" v-if="tmo_afecta_materia && dmo_codtmo == 2713">
                        <label>Materias con uso de Laboratorio</label>
                        <select class="browser-default" v-model="dmo_codmat">
                            <option value="">Seleccione</option>
                            <option v-for="materia in materias_laboratorio_enfermeria" v-bind:key="materia" v-bind:value="materia.mat_codigo">{{materia.mat_codigo}} - {{materia.mat_nombre}} (Ciclo {{materia.plm_ciclo}})</option>
                        </select>
                    </div>
                    <div class="col m3" v-if="tmo_afecta_materia && dmo_codtmo == 2710">
                        <label>Materias con Práctica Clínica</label>
                        <select class="browser-default" v-model="dmo_codmat">
                            <option value="">Seleccione</option>
                            <option v-for="materia in materias_practica_clinica" v-bind:key="materia" v-bind:value="materia.mat_codigo">{{materia.mat_codigo}} - {{materia.mat_nombre}} (Ciclo {{materia.plm_ciclo}})</option>
                        </select>
                    </div>
                    <div class="col m1">
                        <label>Descuento</label>
                        <input type="text" class="text-right" disabled v-model="dmo_descuento">
                    </div>
                    <div class="col m1">
                        <label>Valor</label>
                        <a title="Ver costos." @click="getCostos(estudiante.tipo)" v-if="ver_costos" href="#!" class="right"><i style="font-size:18px" class="material-icons">list</i></a>
                        <input type="text" class="text-right" v-model="dmo_valor">
                    </div>   
                    <div class="col m1">
                        <button type="submit" :disabled="dmo_codtmo == 0" class="btn blue-ugb" style="margin-top:10px"><i class="material-icons">add_circle</i></button>
                    </div>
                </div>
            </form>
        </div>

        <div class="row m0 p0" style="min-height:115px">
            <div class="col m12" v-show="detalle_movimiento.length > 0" style="margin-top:20px">
                <table class="table tbl-thinner bordered white" style="font-size:12px">
                    <thead>
                        <tr>
                            <th style="width:5%"></th>
                            <th style="width:35%">Movimiento</th>
                            <th style="width:5%">Mes</th>
                            <th style="width:5%">Cant.</th>
                            <th style="width:25%">Materia</th>
                            <th style="width:5%">Desc.</th>
                            <!-- <th style="width:10%">IVA</th> -->
                            <th style="width:10%">Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(detalle, index) in detalle_movimiento" v-bind:key="detalle">
                            <td class="center"><a @click="eliminarDetalle(index)" href="#!" v-if="mov_codigo == 0 && detalle.dmau_cuota == undefined"><i class="material-icons">delete</i></a></td>
                            <td>
                                <form @submit.prevent="guardarEditarDetalle(index)">
                                    <span v-show="index_editando != index">{{detalle.dmo_descripcion}}</span> <span v-show="index_editando != index && detalle.tmo_descripcion != detalle.dmo_descripcion">(Edit.: {{ detalle.tmo_descripcion }})</span>
                                    <input maxlength="2000" minlength="1" v-show="index_editando == index" type="text" v-model="detalle.dmo_descripcion" style="width: 90%">
                                    <a href="#!" v-show="index_editando == -1 && mov_codigo == 0" @click="editarDetalle(index)"><i class="material-icons right" style="font-size: 18px;">edit</i></a>
                                    <a href="#!" type="submit" v-show="index_editando == index" @click="guardarEditarDetalle(index)"><i class="material-icons right" style="font-size: 18px;">save</i></a>
                                </form>
                            </td>                            
                            <td class="center">{{detalle.mes_nombre}}</td>
                            <td class="center">{{detalle.dmo_cantidad}}</td>
                            <td class="center">{{detalle.mat_codigo}} - {{detalle.mat_nombre}}</td>
                            <td class="center">{{detalle.dmo_descuento}}</td>
                            <td style="text-align:right">${{(detalle.dmo_valor + detalle.dmo_iva).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2  })}}</td>
                            <!-- <td style="text-align:right">${{detalle.dmo_valor.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td> -->
                        </tr>                        
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="5"></td>
                            <th class="center">IVA</th>
                            <td style="text-align:right">${{total_iva.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                        </tr>
                        <tr>
                            <td colspan="5"></td>
                            <th class="center">EXENTAS</th>
                            <td style="text-align:right">${{total_documento.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                        </tr>
                        <tr>
                            <td colspan="5"></td>
                            <th class="center">TOTAL</th>
                            <td style="text-align:right">${{(total_documento + total_iva).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
    <div class="row" v-if="(mov_serie_documento == '' || mov_recibo == '') && !is_loading_number">
        <div class="col m12 darken-4 card-panel red white-text">
            <span>No puede facturar sin un numero de serie y correlativo.</span>
            <i class="material-icons right">warning</i>
        </div>
    </div>
    <div class="modal" id="modal-concepto">
        <div class="modal-body">
            <div class="row m0 p0">
                <div class="col m2">
                    <label>Carnet</label>
                    <p>{{per_carnet}}</p>
                </div>
                <div class="col m5">
                    <label>Nombre</label>
                    <p>{{per_apellidos_nombres}}</p>
                </div>
                <div class="col m5">
                    <label>Carrera</label>
                    <p>{{estudiante.car_nombre}}</p>
                </div>
            </div>
            <div class="row m0 p0">
                <div class="col m12">
                    <label>Concepto</label>
                    <textarea v-model="mov_descripcion_temp" class="materialize-textarea"></textarea>
                </div>
            </div>
            <div class="row m0 p0">
                <div class="col m2">
                    <button type="submit" class="btn blue-ugb" @click="actualizarConcepto()">GUARDAR</button>
                </div>
                <div class="col m2">
                    <button type="button" class="btn red darken-2" @click="modal_concepto.close()">CANCELAR</button>
                </div>
            </div>
        </div>
    </div>

    <div id="modal-proceso-graduacion" class="modal">
        <div class="modal-content">
            <div class="row">
                <div class="col m3">
                    <label>Tipo</label>
                    <p>{{proceso_graduacion.tpg_descripcion}}</p>
                </div>
                <div class="col m4">
                    <label>Proceso</label>
                    <p>{{proceso_graduacion.pgr_descripcion}}</p>
                </div>
                <div class="col m5">
                    <label>Grupo</label>
                    <p>{{proceso_graduacion.tpg_descripcion}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col m12">
                    <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                        <thead>
                            <tr>
                                <th style="width: 5%">#</th>
                                <th style="width: 55%">Arancel</th>
                                <th style="width: 20%">Cuota</th>
                                <th style="width: 20%">Fecha</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(arancel, index) in proceso_graduacion.aranceles" :key="arancel">
                                <td class="center">{{index + 1}}</td>
                                <td>{{arancel.tmo_descripcion}}</td>
                                <td class="center">${{arancel.tmo_cuota.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                <td class="center">{{arancel.fel_fecha}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" id="modal-seleccionar-cliente" style="min-height: 275px; width: 60%">
        <div class="modal-content m0 p0" >
            <div class="row">
                <div class="col m8 input-field">
                    <input type="text" placeholder="Buscar..." v-model="search_cliente" id="search_cliente" />
                </div>
                <div class="col m4 input field">
                    <button class="btn red darken-2 btn-small"  style="margin-top:12px;" @click="modal_buscar_cliente.close()">Cerrar</button> &nbsp;
                    <button class="btn blue-ugb darken-2 btn-small"  style="margin-top:12px;" @click="getClientes(true)">Recargar</button>
                </div>
                <div class="row m0 p0">
                    <div class="col m12">
                        <a href="/ClientesAdmin" class="btn green darken-2 btn-small right" target="_blank">Nuevo Cliente <i class="material-icons right">open_in_new</i></a>
                    </div>
                </div>
                <div class="col m12" style="height: 175px; overflow:auto">
                    <table class="table tbl-condensed" style="font-size: 12px">
                        <thead>
                            <tr>
                                <th style="width: 5%">ID</th>
                                <th style="width: 40%">Nombre</th>
                                <th style="width: 15%">DUI</th>
                                <th style="width: 20%">NIT</th>
                                <th style="width: 10%">NRC</th>
                                <th style="width: 5%"></th>
                                <th style="width: 5%">Act.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="red lighten-4">
                                <td></td>
                                <td class="center">NINGÚN CLIENTE</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="center"><a href="#!" @click="seleccionarCliente()"><i class="material-icons">check_circle</i></a></td>
                                <td></td>
                            </tr>
                            <tr v-for="cliente in clientes_filter" :key="cliente">
                                <td class="center">{{ cliente.cardCode }}</td>
                                <td>{{ cliente.cardName }}</td>
                                <td class="center">{{ cliente.u_NDUI }}</td>
                                <td class="center">{{ cliente.cli_mh_nit }}</td>
                                <td class="center">{{ cliente.cli_mh_nrc }}</td>
                                <td class="center"><a href="#!" @click="seleccionarCliente(cliente.cardCode)"><i class="material-icons">check_circle</i></a></td>
                                <td class="center"><label><input type="checkbox" @click.prevent="" v-bind:checked="cliente.cli_mh_actualizado" class="filled-in"><span></span></label></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" id="modal-contrasena" style="width: 600x">
        <div class="modal-content m0 p0">
            <div class="row m0 p0">
                <form method="post" v-on:submit.prevent="anular()" autocomplete="off">
                    <div class="row">
                        <div class="col m12">
                            <label><b>{{ estudiante.per_carnet }} </b> - {{estudiante.per_apellidos_nombres}}</label>
                        </div>
                        <div class="col m12">
                            <label><b>N° DOC. INTERNO:</b> {{ mov_recibo }}</label>
                        </div>
                        <div class="col m12">
                            <label><b>CÓDIGO DE GENERACIÓN:</b> {{ mov_mh_codigo_generacion }}</label>
                        </div>
                        <div class="col m12">
                            <label><b>NÚMERO DE CONTROL:</b> {{ mov_mh_numero_control }}</label>
                        </div>
                        <div class="col m12">
                            <label><b>SELLO DE RECIBIDO:</b> {{ mov_mh_sello_recibido }}</label>
                        </div>
                    </div>
                    <div class="row p0 m0">
                        <div class="col m12">
                            <label>Motivo (Máx. 255 caracteres.)</label>
                            <textarea maxlength="255" v-model="mov_motivo_anulacion" class="materialize-textarea" id="txt_motivo"></textarea>
                            <span class="helper-text">{{$validation(validations_cancel, "mov_motivo_anulacion")}}</span>
                        </div>
                    </div>
                    <div class="row p0">
                        <div class="col m4">
                            <label>Contraseña</label>
                            <input type="password" v-model="contrasena" id="txt_contrasena" />
                            <span class="helper-text">{{$validation(validations_cancel, "contrasena")}}</span>
                        </div>
                    </div>
                    <div class="row m0 p0">
                        <div class="col m6">
                            <button type="submit" class="btn blue-ugb" style="width: 100%">Anular</button>
                        </div>
                        <div class="col m6">
                            <button type="button" class="btn red darken-2" @click="modal_autorizar.close()" style="width: 100%">Cancelar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div id="modal-buscar-arancel-alumno" class="modal" style="overflow:hidden">
        <div class="modal-content p0">
            <div class="row">
                <div class="col m12">
                    <label>Buscar</label>
                    <input type="text" v-model="search_arancel" id="txt_search_arancel" />
                </div>
            </div>

            <div class="row">
                <div class="col m12" style="height: 200px; overflow: auto">
                    <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                        <thead>
                            <tr>
                                <th style="width: 70%">Arancel</th>
                                <th style="width: 10%">Exento</th>
                                <th style="width: 10%">Valor Base</th>
                                <th style="width: 10%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="arancel in aranceles_filter" :key="arancel">
                                <td>{{ arancel.tmo_descripcion }}</td>
                                <td class="center"><label><input type="checkbox" class="filled-in" :checked="arancel.tmo_exento == 'N'" @click.prevent=""/><span></span></label></td>
                                <td style="text-align:right">${{ arancel.tmo_valor.toLocaleString('en-US', { minimumFractionDigits: 2 }) }}</td>
                                <td class="center"><a href="#!" @click="seleccionarArancel(arancel.tmo_codigo)"><i class="material-icons">check_circle</i></a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="motivo-anulacion" v-if="mov_estado == 'A'">
        <label class="white-text">
            <b>Motivo:</b> {{ mov_motivo_anulacion }}
        </label>
        <br>
        <label class="white-text">
            <b>Fecha:</b> {{ mov_fecha_anula }}  <b>Usuario: </b> {{ mov_usuario_anula }}
        </label>
    </div>
</template>

<script>
import M from 'materialize-css'
import InputMask from 'primevue/inputmask'
import Datepicker from 'vue3-datepicker'
    export default {
        name: 'FormularioFacturacion',
        components: {
            InputMask,
            Datepicker
        },
        data() {
            return {
                current_tab: 1,
                iva: 0.13,
                porcentaje_recargo: 0.15,
                ciclos: [],
                estados: [],
                tipo_documento: [],
                bancos: [],
                formas_pago: [], //col_mdp_metodo_de_pago
                metodos_pago: [], //col_fdp_formas_de_pago
                aranceles: [],
                meses: [],
                materias_inscritas: [],
                materias_plan: [],
                movimientos_estudiante: [],
                clientes: [],
                cliente: {},
                cardCode: '',

                per_carnet: '',
                per_apellidos_nombres: '',

                mov_codigo: 0,
                mov_recibo: '',
                mov_fecha: new Date(),
                mov_fecha_registro: this.toDMY(new Date()),
                mov_codper: 0,
                mov_codcil: 1,
                mov_descripcion: '',
                mov_descripcion_temp: '',
                mov_tipo_pago: 'E',
                mov_cheque: '',
                mov_estado: 'R',
                mov_tarjeta: '',
                mov_tipo: 'F',
                mov_codban: 0,
                mov_forma_pago: 'E',
                mov_fecha_tarjeta: '',
                mov_voucher: '',
                mov_serie_documento: '',
                mov_ClientCardCode: '',
                mov_impreso: 'N',
                mov_exportado: 'N',
                mov_usuario: '',
                NIT: '',
                NRC: '',
                Address: '',
                mov_pos: 'P',
                mov_mh_procesada: false,
                mov_mh_codigo_generacion: '',
                mov_mh_numero_control: '',
                mov_mh_sello_recibido: '',
                mov_motivo_anulacion: '',
                mov_usuario_anula: '',
                mov_fecha_anula: '',
                mov_comprobante_fisico: false,

                dmo_codtmo: 0,
                dmo_valor: 0,
                dmo_valor_base: 0,
                dmo_codmat: '',
                dmo_iva: 0,
                dmo_mes: new Date().getMonth(),
                dmo_descuento: 0,
                dmo_codmdp: 0,

                detalle_movimiento: [],
                
                cantidad_materias: 1,

                estudiante: {},
                aranceles_estudiante: [],
                is_loading_number: false,
                carreras_salud: [74, 81, 100, 126],

                exoneraciones: [],
                descuentos: [],
                descuentos_fijos: [],
                solvencia: {},
                factura_concepto: false,
                factura_version: 1,

                datepickers: [],

                planes_pago: [],
                autofinanciadas: [],
                modulos_pre: [],
                modales: [],
                modal_concepto: {},
                modal_buscar_cliente: {},
                modal_autorizar: {},
                modal_buscar_arancel: {},

                modo_actualizacion: false,

                notas: [],
                nota_index: -1,

                proceso_graduacion: {},
                modal_proceso_graduacion: {},

                devoluciones: [],
                index_devolucion: -1,

                search_cliente: '',
                search_arancel: '',
                contrasena: '',

                historial_solvencia: [],
                fechas_posgrado: [],
                validations: [],
                validations_cancel: [],
                decimal_100: 10000,
                index_editando: -1
            }
        },
        methods: {
            async GetTime()
            {
                this.$root.startLoading();
                await this.axios.get('/Facturacion/Time')
                .then(response => {
                    this.mov_fecha = new Date(response.data.year, response.data.month - 1, response.data.day);
                });
                this.$root.stopLoading();
            },
            async init(codmov)
            {                           
                this.setMovTipoPago();
                if(this.ciclos.length == 0)
                {
                    await this.axios.get('/Ciclos')
                    .then(response => {
                        this.ciclos = response.data;
                    });
                    if(this.ciclos.length > 0)
                    {
                        this.mov_codcil = this.ciclos[0].cil_codigo;
                    }
                }

                if(this.estados.length == 0)
                {
                    await this.axios.get('/TipoEstadoDocumento')
                    .then(response => {
                        this.estados = response.data;
                    });
                }

                if(this.tipo_documento.length == 0)
                {
                    await this.axios.get('/TipoDocumento?allowed=true')
                    .then(response => {
                        this.tipo_documento = response.data;
                        if(this.tipo_documento.length > 0)
                        {
                            this.mov_tipo = this.tipo_documento[0].tdo_sigla;
                        }
                    })
                }

                if(this.bancos.length == 0)
                {
                    await this.axios.get('/Bancos')
                    .then(response => {
                        this.bancos = response.data;
                    });
                }

                if(this.formas_pago.length == 0)
                {
                    await this.axios.get('/FormasPago')
                    .then(response => {
                        this.formas_pago = response.data;
                    });
                }

                if(this.metodos_pago.length == 0)
                {
                    await this.axios.get('/MetodosPago')
                    .then(response => {
                        this.metodos_pago = response.data;
                    });
                }

                if(this.aranceles.length == 0)
                {
                    await this.axios.get('/Aranceles/Facturacion')
                    .then(response => {
                        this.aranceles = response.data.filter(x=>x.tmo_estado == 'A');
                    });
                }

                if(this.meses.length == 0)
                {
                    await this.axios.get('/Meses')
                    .then(response => {
                        this.meses = response.data;
                    })
                }

                if(codmov == 0)
                {
                    await this.getNumeroRecibo(false);
                }

                await this.getClientes();

                if(codmov > 0)
                {
                    await this.axios.get('/Movimientos/' + codmov)
                    .then(response => {
                        this.mov_codigo = response.data.mov_codigo;
                        this.mov_codcil = response.data.mov_codcil;
                        this.mov_fecha = this.toDateFormat(response.data.mov_fecha);
                        this.mov_fecha_registro = response.data.mov_fecha_registro;
                        this.mov_tipo = response.data.mov_tipo;
                        this.mov_serie_documento = response.data.mov_serie_documento;
                        this.mov_recibo = response.data.mov_recibo;
                        this.mov_codban = response.data.mov_codban;
                        this.mov_forma_pago = response.data.mov_forma_pago;
                        this.mov_tipo_pago = response.data.mov_tipo_pago;
                        this.mov_codper = response.data.mov_codper;
                        this.per_carnet = response.data.per_carnet;
                        this.per_apellidos_nombres = response.data.per_apellidos_nombres;
                        this.mov_descripcion = response.data.mov_descripcion;
                        this.mov_estado = response.data.mov_estado;
                        this.mov_impreso = response.data.mov_impreso;
                        this.mov_exportado = response.data.mov_exportado;
                        this.mov_tarjeta = response.data.mov_tarjeta;
                        this.mov_fecha_tarjeta = response.data.mov_fecha_tarjeta;
                        this.mov_voucher = response.data.mov_voucher;
                        this.mov_cheque = response.data.mov_cheque;
                        this.mov_usuario = response.data.mov_usuario;
                        this.mov_mh_procesada = response.data.mov_mh_procesada;
                        this.mov_mh_codigo_generacion = response.data.mov_mh_codigo_generacion;
                        this.mov_mh_numero_control = response.data.mov_mh_numero_control;
                        this.mov_mh_sello_recibido = response.data.mov_mh_sello_recibido;
                        this.mov_usuario_anula = response.data.mov_usuario_anula;
                        this.mov_motivo_anulacion = response.data.mov_motivo_anulacion;
                        this.mov_fecha_anula = response.data.mov_fecha_anula;
                        this.mov_comprobante_fisico = response.data.mov_comprobante_fisico;
                        this.detalle_movimiento = response.data.detalle;
                        this.detalle_movimiento.forEach(x=>x.dmo_descripcion = x.dmo_descripcion || x.tmo_descripcion);

                        this.factura_concepto = false;
                        this.factura_version = 1;
                        document.querySelector('#chk_factura_concepto').checked = false;
                        document.querySelector('#chk_factura_version').checked = false;
                        if((response.data.mov_codcli || 0) > 0)
                        {
                            this.factura_concepto = true;
                            document.querySelector('#chk_factura_concepto').checked = true;
                        }

                        this.mov_ClientCardCode = response.data.clientCardCode;
                        if((this.mov_ClientCardCode || '') != '')
                        {
                            var cliente = this.clientes.find(x=>x.cardCode == this.mov_ClientCardCode);
                            if(cliente != undefined)
                            {
                                this.cliente = cliente;
                            }
                        }
                    });

                    await this.getDatosEstudiante();
                    await this.getDevoluciones();
                    await this.setLog('Abre movimiento ' + codmov);
                }
                else
                {
                    await this.GetTime();
                }
            },
            async setEstudiante(estudiante)
            {   
                if(this.mov_codper > 0 && this.detalle_movimiento.length > 0)
                {
                    if(!confirm('Si selecciona otro estudiante se eliminaran todos los aranceles agregados. ¿Desea cambiar de estudiante?')) return false;
                }
                this.detalle_movimiento = [];
                this.movimientos_estudiante = [];
                this.estudiante = {};
                this.current_tab = 1;
                this.dmo_codtmo = 0;
                this.mov_codper = estudiante.per_codigo;
                this.per_carnet = estudiante.per_carnet;
                this.per_apellidos_nombres = estudiante.per_apellidos_nombres;
                await this.getDatosEstudiante();
                await this.getDevoluciones();
                await this.setLog('Selecciona estudiante ' + this.estudiante.per_carnet + ' ('+this.estudiante.per_codigo+')');
            },
            async getNumeroRecibo(load)
            {
                if(load)
                    this.$root.startLoading();
                this.is_loading_number = true;
                await this.axios.get('/Facturacion/Recibo/' + this.mov_tipo)
                .then(response => {
                    this.mov_serie_documento = response.data.serie;
                    this.mov_recibo = response.data.recibo;
                })
                .catch(() => {
                    
                });
                this.is_loading_number = false;
                if(load)
                    this.$root.stopLoading();
            },
            cerrarModal()
            {
                this.resetForm(true, 'C');
            },
            async resetForm(valid, type = 'C')
            {
                var _valid = valid || false;
                if(_valid)
                {
                    if(this.mov_codigo == 0 && this.detalle_movimiento.length > 0)
                    {
                        if(type == 'N')
                        {
                            if(!confirm('El documento no ha sido guardado y presenta aranceles agregados ¿desea crear un nuevo documento?')) return false;
                        }

                        if(type == 'C')
                        {
                            if(!confirm('El documento no ha sido guardado y presenta aranceles agregados ¿desea cerrar esta ventana?')) return false;
                        }

                        await this.setLog('Cancelación de selección para estudiante ' + this.estudiante.per_carnet + ' ('+this.estudiante.per_codigo+') ' + '[Tipo = '+this.mov_tipo+', Fecha = '+this.mov_fecha+', Banco = '+this.mov_codban+', Forma de Pago = '+this.mov_forma_pago+',  Método de Pago = '+this.mov_tipo_pago+', Cliente = '+this.mov_ClientCardCode+', Concepto = '+this.mov_descripcion+']' + ' con los movimientos: ' + JSON.stringify(this.detalle_movimiento));
                    }
                }
                this.per_carnet = '';
                this.per_apellidos_nombres = '';
                this.mov_codigo = 0;
                this.mov_recibo = '';

                this.mov_fecha = this.mov_tipo == 'R' && this.modo_actualizacion ? this.mov_fecha:new Date();
                this.mov_fecha_registro = this.toDMY(new Date());
                this.mov_codper = 0;
                var vigente = this.ciclos.find(x=>x.cil_vigente == 'S');
                this.mov_codcil = vigente == undefined ? this.ciclos[0].cil_codigo:vigente.cil_codigo;
                this.mov_descripcion = '';
                this.mov_tipo_pago = this.mov_tipo == 'R' && this.modo_actualizacion ? this.mov_tipo_pago:'E';
                this.mov_cheque = '';
                this.mov_estado = 'R';
                this.mov_tarjeta = '';
                if(this.tipo_documento.length > 0)
                {
                    this.mov_tipo = this.mov_tipo == 'R' && this.modo_actualizacion ? this.mov_tipo:this.tipo_documento[0].tdo_sigla;
                }
                this.mov_codban = this.mov_tipo == 'R' && this.modo_actualizacion ? this.mov_codban:0;
                this.mov_forma_pago = this.mov_tipo == 'R' && this.modo_actualizacion ? this.mov_forma_pago:'C';
                this.mov_fecha_tarjeta = '';
                this.mov_voucher = '';
                this.mov_serie_documento = '';
                this.mov_ClientCardCode = '';
                this.mov_impreso = 'N';
                this.dmo_codtmo = 0;
                this.dmo_valor = 0;
                this.dmo_codmat = '';
                this.dmo_iva = 0;
                this.dmo_mes = 0;
                this.dmo_descuento = 0;
                this.dmo_codmat = '';
                this.detalle_movimiento = [];
                this.cantidad_materias = 1;
                this.estudiante = {};
                this.aranceles_estudiante = [];
                this.materias_inscritas = [];
                this.movimientos_estudiante = [];
                this.mov_ClientCardCode = '';
                this.mov_ClientCardName = '';
                this.cliente = {};
                this.current_tab = 1;
                this.autofinanciadas = [];
                this.planes_pago = [];
                this.factura_concepto = false;
                this.factura_version = 1;
                this.mov_pos = 'P';
                this.mov_mh_procesada = false;
                this.mov_mh_codigo_generacion = '';
                this.mov_comprobante_fisico = false;
                document.querySelector('#chk_factura_concepto').checked = false;
                document.querySelector('#chk_factura_version').checked = false;                
                await this.getNumeroRecibo(false);
                if(!this.modo_actualizacion && this.mov_tipo == 'R')
                {
                    await this.GetTime();
                }
                if(this.mov_tipo != 'R')
                {
                    await this.GetTime();
                }
            },
            async getCostoArancel()
            {
                this.dmo_codmat = '';
                if(this.dmo_codtmo > 0)
                {
                    var plan_pago = this.planes_pago.find(x=>x.codigoCiclo == this.mov_codcil);
                    if(plan_pago != undefined)
                    {
                        var arancel = plan_pago.plan.find(x=>x.codigoArancel == this.dmo_codtmo);
                        if(arancel != undefined)
                        {
                            this.dmo_valor = arancel.valor;
                            this.dmo_valor_base = arancel.valor;
                            this.dmo_descuento = 0;
                            this.dmo_mes = arancel.mes;
                            M.toast({html: 'La cuota posee plan de pago '+(arancel.moraIncluida ? 'con':'sin')+' mora incluida.'});
                            return;
                        }
                    }
                    
                    var ciclo_autofinanciada = this.autofinanciadas.find(x=>x.codigoCiclo == this.mov_codcil);
                    if(ciclo_autofinanciada != undefined)
                    {
                        var pago_autofinanciado = ciclo_autofinanciada.cuotas.find(x=>x.codigoArancel == this.dmo_codtmo);
                        if(pago_autofinanciado != undefined)
                        {
                            M.toast({html: 'Seleccione la o las cuotas a pagar.'});
                            this.$parent.detalleAutofinanciada();
                            return;
                        }
                    }

                    this.$root.startLoading();
                    await this.axios.get('/Aranceles/' + this.dmo_codtmo  + '/Valor/' + this.mov_codcil  + '/' + this.mov_codper + '/' + this.estudiante.car_codigo + '/' + this.estudiante.fel_tipo + '/' + this.estudiante.tipo + '/' + this.cantidad_materias)
                    .then(response => {
                        this.dmo_valor = response.data.valor;
                        this.dmo_descuento = response.data.descuento;
                        this.dmo_mes = response.data.mes;
                        this.dmo_valor_base = response.data.valorBase;
                    });
                    this.$root.stopLoading();
                }
            },
            async getDatosEstudiante()
            {
                if(this.mov_codper == 0) return false;
                this.$root.startLoading();
                await this.axios.get('/Estudiantes/' + this.mov_codper + '/Detalle/' + this.mov_codcil)
                .then(response => {
                    this.estudiante = response.data;
                })
                .catch(error => {
                    if(error.response.status == 404)
                    {
                        M.toast({html: 'Estudiante no encontrado.'});
                    }
                });

                var ciclo = this.ciclos.find(x=>x.cil_codigo == this.mov_codcil);
                var anio = ciclo.cil_anio;
                var codcic = ciclo.cic_codigo_int;
                await this.axios.get('/Estudiantes/'+this.mov_codper+'/Inscritas/' + this.mov_codcil + '/' + anio + '/' + codcic)
                .then(response => {
                    this.materias_inscritas = response.data;
                });

                await this.axios.get('/Planes/' + this.estudiante.pla_codigo + "/Materias")
                .then(response => {
                    this.materias_plan = response.data;
                });

                this.aranceles_estudiante = [];
                if(this.estudiante.per_tipo == 'U' && (this.estudiante.fel_tipo == 'N' || this.estudiante.fel_tipo == 'V'))
                {                    
                    await this.axios.get('/Estudiantes/' + this.mov_codper + '/Aranceles/' + anio + '/' +  codcic)
                    .then(response => {
                        this.aranceles_estudiante = response.data;
                    });
                }
                
                await this.getPlanesPago();
                await this.getAutofinanciadas();
                await this.getModulosPre();
                await this.detalleProcesoGraduacion();
                await this.getHistorialSolvencia();
                await this.getFechasPosgrado();
                this.$root.stopLoading();
            },
            async getPlanesPago()
            {
                this.planes_pago = [];
                await this.axios.get('/PlanPagos/Estudiante/' + this.mov_codper)
                .then(response => {
                    if(response.status == 200)
                        this.planes_pago = response.data;
                });
            },
            async getAutofinanciadas()
            {
                this.autofinanciadas = [];
                await this.axios.get('/Autofinanciadas/Estudiante/' + this.mov_codper)
                .then(response => {
                    this.autofinanciadas = response.data;
                });
            },
            async getModulosPre()
            {
                this.modulos_pre = [];
                if(this.estudiante.fel_tipo == 'P')
                {
                    await this.axios.get('/Preespecializacion/' + this.estudiante.pre_codigo + '/Modulos')
                    .then(response => {
                        this.modulos_pre = response.data;
                    });
                }
            },
            setValorAutofinanciada()
            {                
                var autofinanciada = this.autofinanciadas.find(x=>x.codigoCicloPago == this.mov_codcil);
                if(autofinanciada == null) 
                {
                    this.dmo_valor = 0;
                    return;
                }
                var cuotas = autofinanciada.cuotas.filter(x=>x.codigoMateria == this.dmo_codmat);
                if(cuotas.length == 0)
                {
                    this.dmo_valor = 0;
                    return;
                }
                var total_agregados = this.detalle_movimiento.filter(x=>x.mat_codigo == this.dmo_codmat && this.dmo_codtmo == 75);
                var siguiente_cuota = cuotas[total_agregados.length];
                this.dmo_valor = siguiente_cuota == undefined ? 0:siguiente_cuota.valor;
            },
            cambiarCantidadMaterias()
            {
                if(this.aranceles_estudiante.length > 0)
                {
                    this.dmo_valor = this.aranceles_estudiante.find(x=>x.cantidad == this.cantidad_materias).valor;
                    this.dmo_valor_base = !this.estudiante.bec_recargo_completo ? this.dmo_valor:this.aranceles_estudiante.find(x=>x.cantidad == this.cantidad_materias).valorBase;
                }
            },
            async agregarDetalle()
            {
                //Para agregarlos al final de la lista
                var aranceles_extras = [];

                if(this.dmo_valor <= 0)
                {
                    M.toast({html: 'No puede agregar un arancel con valor de cero.'});
                    return;
                }

                var arancel = this.aranceles.find(x=>x.tmo_codigo == this.dmo_codtmo);
                                
                if(this.estudiante.fel_tipo != 'P' && arancel.tmo_afecta_materia == 'S' && this.dmo_codmat == '')
                {
                    M.toast({html: 'Seleccione una materia.'});
                    return;
                }

                this.dmo_codmat = arancel.tmo_afecta_materia == "N" || this.estudiante.fel_tipo == 'P' ? '':this.dmo_codmat;

                var ciclo = this.ciclos.find(x=>x.cil_codigo == this.mov_codcil);
                var dmo_valor_neto = this.dmo_valor - (this.dmo_valor * this.dmo_descuento / 100);

                //Arancel cancelado si es unico
                var existe_arancel = false;
                if(arancel.tmo_unico == 'S')
                {
                    if(this.detalle_movimiento.find(x=>x.tmo_codigo == this.dmo_codtmo) != undefined)
                    {
                        M.toast({html: 'Ya tienes agregado este arancel.'});
                        return;
                    }
                    this.$root.startLoading();
                    await this.axios.get('/Movimientos/ArancelCancelado/' + this.mov_codper + '/' + this.mov_codcil + '/' + this.dmo_codtmo)
                    .then(response => {
                        existe_arancel = response.status == 200;
                    });
                    this.$root.stopLoading();
                }
                
                if(existe_arancel)
                {
                    M.toast({html: 'El arancel ya se encuentra cancelado.'});
                    return;
                }

                //Requisito
                var cumple_requisito = true;
                if((arancel.tmo_codtmo_req > 0 || arancel.tmo_codtmo_req_v > 0) && this.detalle_movimiento.find(x=>x.dmo_codtmo == arancel.tmo_codtmo_req || x.dmo_codtmo == arancel.tmo_codtmo_req_v) == undefined)
                {
                    this.$root.startLoading();
                    await this.axios.get('/Movimientos/Requisito/' + this.mov_codper + '/' + this.mov_codcil + '/' + this.dmo_codtmo)
                    .then(() => {
                        cumple_requisito = true;
                    })
                    .catch(() => {
                        cumple_requisito = false;
                    });
                    this.$root.stopLoading();
                }

                if(!cumple_requisito)
                {
                    M.toast({html: 'Debe cancelar primero el arancel anterior.'});
                    return;
                }

                //Cuota en plan de pago
                var plan_pago = this.planes_pago.find(x=>x.codigoCiclo == this.mov_codcil);
                if(plan_pago != undefined)
                {
                    var arancel_plan = plan_pago.plan.find(x=>x.codigoArancel == this.dmo_codtmo);
                    if(arancel_plan != undefined)
                    {                        
                        let valor = arancel_plan.moraIncluida ?
                                      Math.round((arancel_plan.valorBase - (arancel_plan.valorBase / 1.15)) * this.decimal_100) / this.decimal_100
                                    : Math.round(arancel_plan.valorBase * this.porcentaje_recargo * this.decimal_100) / this.decimal_100;
                        if(arancel_plan.moraIncluida)
                        {
                            arancel_plan.valor = arancel_plan.valor - valor;
                            arancel_plan.valorBase = arancel_plan.valorBase - valor;
                        }
                        var data_plan = {
                            "dmo_codigo": 0,
                            "tmo_codigo": arancel.tmo_codigo,
                            "dmo_codtmo": this.dmo_codtmo,
                            "tmo_descripcion": arancel_plan.arancel,
                            "mes_nombre": arancel_plan.mesNombre,
                            "mat_codigo": '',
                            "mat_nombre": '',
                            "dmo_descuento": 0,
                            "dmo_valor": arancel_plan.valor,
                            "dmo_iva": 0,
                            "tmo_codtmo_req": arancel.tmo_codtmo_req,
                            "tmo_codtmo_req_v": arancel.tmo_codtmo_req_v,
                            "dmo_cantidad": 1
                        };

                        aranceles_extras.push(data_plan);

                        if(arancel_plan.conRecargo || arancel_plan.moraIncluida)
                        {
                            let mora_valor = valor / (1 + this.iva);
                            let mora_iva = valor - mora_valor;
                            var mora_plan = {
                                "dmo_codigo": 0,
                                "tmo_codigo": 70,
                                "dmo_codtmo": 70,
                                "tmo_descripcion": 'Recargo ' + arancel_plan.arancel + ' (15%)',
                                "mes_nombre": arancel_plan.mesNombre,
                                "mat_codigo": '',
                                "mat_nombre": '',
                                "dmo_descuento": 0,
                                "dmo_valor": Math.round(mora_valor * this.decimal_100) / this.decimal_100,
                                "dmo_iva": Math.round(mora_iva * this.decimal_100) / this.decimal_100,
                                "tmo_codtmo_req": 0,
                                "tmo_codtmo_req_v": 0,
                                "dmo_cantidad": 1
                            };
                            aranceles_extras.push(mora_plan);
                        }
                        this.detalle_movimiento = this.detalle_movimiento.concat(aranceles_extras);
                        return;
                    }
                }
                
                var mes = this.meses.find(x=>x.mes_codigo == this.dmo_mes);

                //Mora, omititmos para recibos
                //if(this.mov_tipo != 'R' && arancel.tmo_genera_mora == 'S')
                if(arancel.tmo_genera_mora == 'S')
                {
                    this.$root.startLoading();
                    var mora_vm = {
                        mov_codcil: this.mov_codcil,
                        dmo_codtmo: this.dmo_codtmo,
                        fel_tipo: this.estudiante.fel_tipo,
                        dmo_valor: dmo_valor_neto,
                        nuevo_ingreso: this.estudiante.per_nuevo_ingreso,
                        dmo_valor_base: this.dmo_valor_base,
                        bec_codigo: this.estudiante.bec_codigo,
                        per_codigo: this.estudiante.per_codigo,
                        mov_fecha: this.mov_fecha
                    };
                    await this.axios.post('/Movimientos/Mora/', mora_vm)
                    .then(response => {
                        if(response.status == 200)
                        {
                            var _mora = response.data;
                            _mora.dmo_mes = this.dmo_mes;
                            _mora.mes_nombre = mes == undefined ? '':mes.mes_nombre;
                            aranceles_extras.push(_mora);
                        }
                    });
                    this.$root.stopLoading();
                }

                this.$root.startLoading();
                var practicas_enfermeria = !this.estudiante.car_es_enf ? 0:this.materias_inscritas.filter(x=>x.plm_horas_clinica > 0 && x.mai_estado == 'I').length;
                var laboratorio_enfermeria = !this.estudiante.car_es_enf ? 0:this.materias_inscritas.filter(x=>x.plm_laboratorio == 'S' && x.mai_estado == 'I').length;
                var practicas_laboratorio = this.materias_inscritas.filter(x=>x.plm_laboratorio == 'S' && x.mai_estado == 'I').length;
                await this.axios.get('/Movimientos/ArancelesAdicionales/' + this.mov_codcil + '/' + ciclo.cil_anio + '/' + ciclo.cic_codigo_int + '/' + this.mov_codper + '/' + this.estudiante.car_codigo + '/' + this.dmo_codtmo + '/' + practicas_laboratorio + '/' + laboratorio_enfermeria + '/' + practicas_enfermeria)
                .then(response => {
                    aranceles_extras = aranceles_extras.concat(response.data);
                });
                this.$root.stopLoading();

                
                var mat_nombre = '';
                if(this.estudiante.fel_tipo != 'P' && arancel.tmo_afecta_materia == 'S')
                {
                    if(this.dmo_codtmo == 75)
                    {
                        mat_nombre = this.materias_autofinanciadas.find(x=>x.codigoMateria == this.dmo_codmat).materia;
                    }
                    else
                    {
                        let _materia_seleccionada = this.materias_inscritas.find(x=>x.mat_codigo == this.dmo_codmat);
                        mat_nombre = _materia_seleccionada == undefined ? this.materias_laboratorio_enfermeria.find(x=>x.mat_codigo == this.dmo_codmat).mat_nombre:_materia_seleccionada.mat_nombre;
                    }
                }

                var dmo_valor = arancel.tmo_exento == 'S' ? dmo_valor_neto / (1 + this.iva):dmo_valor_neto;
                var dmo_iva = dmo_valor_neto - dmo_valor;
                var data = {
                    "dmo_codigo": 0,
                    "tmo_codigo": arancel.tmo_codigo,
                    "dmo_codtmo": this.dmo_codtmo,
                    "tmo_descripcion": arancel.tmo_descripcion,
                    "dmo_descripcion": arancel.tmo_descripcion,
                    "mes_nombre": this.dmo_mes == 0 ? '':mes.mes_nombre,
                    "dmo_codmat": this.dmo_codmat,
                    "mat_codigo": this.dmo_codmat,
                    "mat_nombre": mat_nombre,
                    "dmo_descuento": this.dmo_descuento,
                    "dmo_valor": Math.round(dmo_valor * this.decimal_100) / this.decimal_100,
                    "dmo_iva": Math.round(dmo_iva * this.decimal_100) / this.decimal_100,
                    "tmo_codtmo_req": arancel.tmo_codtmo_req,
                    "tmo_codtmo_req_v": arancel.tmo_codtmo_req_v,
                    "dmo_cantidad": 1,
                    "dmo_mes": this.dmo_mes
                };

                this.detalle_movimiento.push(data);
                aranceles_extras.forEach(x=>{
                    x.dmo_descripcion = x.tmo_descripcion
                });
                this.detalle_movimiento = this.detalle_movimiento.concat(aranceles_extras);

                await this.setLog('Agregar al estudiante ' + this.estudiante.per_carnet + ' ('+this.estudiante.per_codigo+') el arancel: ' + JSON.stringify(data) + ' y los aranceles extras: ' + JSON.stringify(aranceles_extras));

                this.dmo_codtmo = 0;
                this.cantidad_materias = 1;
                this.dmo_valor = 0;
                this.dmo_codmat = '',
                this.dmo_iva = 0,
                this.dmo_mes = 0;
                this.dmo_descuento = 0;
            },
            eliminarDetalle(index)
            {
                var detalle = this.detalle_movimiento[index];
                var codtmo = detalle.dmo_codtmo;
                if(this.detalle_movimiento.find(x=>(x.tmo_codtmo_req == codtmo || x.tmo_codtmo_req_v == codtmo) && x.dmo_codtmo != codtmo) != undefined)
                {
                    M.toast({html: 'No puede eliminar este arancel, es requisito para uno de los aranceles agregados.'});
                    return false;
                }
                
                this.setLog('Elimina para el estudiante ' + this.estudiante.per_carnet + ' ('+this.estudiante.per_codigo+') el arancel: ' + JSON.stringify(this.detalle_movimiento[index]));
                this.detalle_movimiento.splice(index, 1);
            },
            async guardar()
            {
                this.validations = [];
                if((this.mov_ClientCardCode || '') != '' && this.$isNullOrWhitespace(this.mov_descripcion))
                {
                    M.toast({html: 'El concepto no puede estar vacío cuando selecciona un cliente.'});
                    return false;
                }
                if(this.mov_codigo == 0)
                {
                    if(!confirm('¿Desea guardar este documento?\nUna vez creado no podra realizar cambios.')) return false;
                }
                var data = {
                    mov_codigo: this.mov_codigo,
                    mov_codcil: this.mov_codcil,
                    mov_fecha: this.mov_fecha,
                    mov_tipo: this.mov_tipo,
                    mov_serie_documento: this.mov_serie_documento,
                    mov_recibo: this.mov_recibo,
                    mov_codban: this.mov_codban,
                    mov_forma_pago: this.mov_forma_pago,
                    mov_tipo_pago: this.mov_tipo_pago,
                    mov_codper : this.mov_codper,
                    mov_descripcion: this.mov_descripcion,
                    col_dmo_det_mov: this.detalle_movimiento,
                    mov_fecha_registro: this.toYMD(this.mov_fecha_registro),
                    mov_cheque: this.mov_cheque,
                    mov_tarjeta: this.mov_tarjeta,
                    mov_fecha_tarjeta: this.mov_fecha_tarjeta,
                    mov_voucher: this.mov_voucher,
                    ClientCardCode: this.mov_ClientCardCode,
                    ClientCardName: this.mov_ClientCardName,
                    NIT: this.NIT,
                    NRC: this.NRC,
                    Address: this.Address,
                    mov_codcli: parseInt(this.mov_ClientCardCode),
                    mov_pos: this.mov_pos,
                    mov_comprobante_fisico: this.mov_comprobante_fisico
                };
                this.$root.startLoading();
                await this.axios.post('/Movimientos', data)
                .then(async response => {
                    this.mov_codigo = response.data.mov_codigo;
                    this.mov_impreso = response.data.mov_impreso;
                    this.mov_estado = response.data.mov_estado;
                    this.mov_usuario = response.data.mov_usuario;
                    if((response.data.mov_codcli || 0) > 0)
                    {
                        this.factura_concepto = true;
                        document.querySelector('#chk_factura_concepto').checked = true;
                    }
                    await this.setFacturacionElectronica();
                    this.$parent.getMovimientos(1);
                })
                .catch(error => {
                    if(error.response.status == 400)
                    {
                        if(error.response.data.type == 'C')
                        {
                            M.toast({html: error.response.data.message});
                        }
                        
                        if(error.response.data.type == 'M')
                        {
                            this.validations = error.response.data.modelErrors;
                        }
                    }
                });
                this.$root.stopLoading();
            },
            async setFacturacionElectronica()
            {
                this.$root.startLoading();
                await this.axios.post('/FacturacionElectronicaAPI/Estudiante/' + this.mov_codigo)
                .then(async response => {
                    if(response.status == 200)
                    {
                        this.mov_mh_codigo_generacion = response.data.content.transaction.codigoGeneracion;
                        this.mov_mh_procesada = true;
                        await this.imprimirFE();
                    }
                })
                .catch(() => {
                    M.toast({html: 'El documento no pudo ser registrado en el DTE, se enviará posteriormente.'});
                });
                this.$root.stopLoading();
            },
            imprimible()
            {
                if(this.mov_codigo == 0) return false;
                if(this.mov_tipo == 'R') return false;
                if(this.mov_fecha < new Date(2024, 0, 1))
                {
                    return true;
                }
                return false;
            },
            async verificarFactura()
            {
                await this.axios.get('/FacturacionElectronicaAPI/Verificar/' + this.mov_mh_codigo_generacion)
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
            },
            modalAutorizar()
            {
                this.contrasena = '';
                this.mov_motivo_anulacion = '';
                this.validations_cancel = [];
                this.modal_autorizar = M.Modal.getInstance(document.querySelector('#modal-contrasena'));
                this.modal_autorizar.open();
                document.querySelector('#txt_motivo').focus();
                return;
            },
            async actualizar()
            {                
                if((this.mov_ClientCardCode || '') != '' && this.$isNullOrWhitespace(this.mov_descripcion))
                {
                    M.toast({html: 'El concepto no puede estar vacío cuando selecciona un cliente.'});
                    return false;
                }
                if(this.mov_codigo == 0)
                {
                    if(!confirm('¿Desea guardar este documento?\nUna vez creado no podra realizar cambios.')) return false;
                }
                var data = {
                    mov_codigo: this.mov_codigo,
                    mov_codcil: this.mov_codcil,
                    mov_fecha: this.mov_fecha,                    
                    mov_codban: this.mov_codban,                    
                    contrasena: this.contrasena,
                    mov_comprobante_fisico: this.mov_comprobante_fisico
                };
                this.$root.startLoading();
                await this.axios.put('/Movimientos/' + this.mov_codigo, data)
                .then(async () => {                    
                    await this.$parent.getMovimientos(1);
                    this.contrasena = '';
                    M.toast({html: 'El registro ha sido actualizado.'});
                })
                .catch(error => {
                    if(error.response.status == 400)
                    {
                        if(error.response.data.type == 'C')
                        {
                            M.toast({html: error.response.data.message});
                        }
                    }
                    if(error.response.status == 403)
                    {
                        M.toast({html: 'No está autorizado para realizar cambios.'});
                    }
                    this.contrasena = '';
                    document.querySelector('#txt_motivo').focus();
                });
                this.$root.stopLoading();
            },
            async getMovimientosEstudiantes(reload)
            {
                if(reload || (this.movimientos_estudiante.length == 0 && !reload))
                {
                    this.$root.startLoading();
                    var ciclo = this.ciclos.find(x=>x.cil_codigo == this.mov_codcil);
                    var anio = ciclo.cil_anio;
                    var codcic = ciclo.cic_codigo_int;
                    await this.axios.get('/Estudiantes/' + this.mov_codper + '/Movimientos/' + anio + '/' + codcic)
                    .then(response => {
                        this.movimientos_estudiante = response.data;
                    });
                    this.$root.stopLoading();
                }
            },
            async getDescuentosExoneraciones(reload)
            {                
                if(reload || ((this.exoneraciones.length == 0 || this.descuentos.length == 0) && !reload))
                {
                    this.$root.startLoading();
                    var ciclo = this.ciclos.find(x=>x.cil_codigo == this.mov_codcil);
                    await this.axios.get('/Exoneraciones/' + this.mov_codper + '/' + ciclo.cil_anio + '/' + ciclo.cic_codigo_int)
                    .then(response => {
                        this.exoneraciones = response.data;
                    });
                    await this.axios.get('/Descuentos/' + this.mov_codper + '/' + ciclo.cil_anio + '/' + ciclo.cic_codigo_int)
                    .then(response => {
                        this.descuentos = response.data;
                    });
                    await this.axios.get('/Estudiantes/'+this.mov_codper+'/DescuentoFijo/' + ciclo.cil_anio + '/' + ciclo.cic_codigo_int)
                    .then(response => {
                        this.descuentos_fijos = response.data;
                    });
                    this.$root.stopLoading();
                }
            },
            async getSolvencia(reload)
            {
                if(reload || (this.solvencia == null && !reload))
                {
                    this.$root.startLoading();
                    var tipo = this.estudiante.tipo;
                    var ciclo = this.ciclos.find(x=>x.cil_codigo == this.mov_codcil);
                    if(tipo == 'N')
                    {
                        await this.axios.get('/Solvencia/Pregrado/' + this.mov_codper + '/' + ciclo.cil_anio + '/' + ciclo.cic_codigo_int)
                        .then(response => {
                            this.solvencia = response.data;
                        });
                    }
                    this.$root.stopLoading();
                }
            },
            async getCostos(tipo)
            {
                await this.$parent.getCostos(tipo, this.mov_codcil, this.estudiante.car_codigo, this.mov_codper);
            },
            arancel_visible(arancel) {
                if(arancel.tmo_tipo_mov == 'N') return true;
                if((arancel.tmo_tipo_mov == 'M' || arancel.tmo_tipo_mov == 'C') && arancel.tmo_virtual == 'S' && this.estudiante.car_virtual == 'V') return true;
                if((arancel.tmo_tipo_mov == 'M' || arancel.tmo_tipo_mov == 'C') && arancel.tmo_virtual == 'N' && this.estudiante.car_virtual == 'P') return true;
            },
            toDMY(date)
            {
                let day = date.getDate().toString();
                let month = date.getMonth() + 1;
                let year = date.getFullYear();
                if(day < 10)
                {
                    day = '0' + day;
                }
                if(month < 10) {
                    return `${day}/0${month}/${year}`;
                } else {
                    return `${day}/${month}/${year}`;
                }
            },
            toYMD(date)
            {
                return date.substring(6, 10) + '-' + date.substring(3, 5) + '-' + date.substring(0, 2);
            },
            toDateFormat(date)
            {
                var day = parseInt(date.substring(0, 2));
                var month = parseInt(date.substring(3,5)) - 1;
                var year = parseInt(date.substring(6,10));
                return new Date(year, month, day);
            },
            async eliminar()
            {
                if(confirm('¿Desea eliminar este documento?'))
                {
                    this.$root.startLoading();
                    await this.axios.delete('/Movimientos/' + this.mov_codigo)
                    .then(async () => {
                        this.$parent.getMovimientos(1);
                        await this.resetForm();
                    });
                    this.$root.stopLoading();
                }
            },
            async imprimir()
            {
                if(this.mov_tipo != 'F' && this.mov_tipo != 'C') return false;

                if(this.factura_concepto)
                {
                    if(this.$isNullOrWhitespace(this.mov_descripcion))
                    {
                        if(confirm('El concepto se encuentra vacío, no puede imprimir el documento.\n¿Desea actualizarlo?'))
                        {
                            this.modal_concepto = M.Modal.getInstance(document.querySelector('#modal-concepto'));
                            this.modal_concepto.open();
                            return false;
                        }
                        return false;
                    }
                }

                var segmento = this.factura_concepto ? 'AlumnoConcepto':'Alumno';
                var version = this.factura_version ? 1:2;
                var url_api = this.mov_tipo == 'F' ? '/Reportes/' + segmento +'/' + this.mov_codigo + '/' + version: '/Reportes/CreditoFiscalAlumno/' + this.mov_codigo;
                this.$root.startLoading();
                await this.axios.get(url_api, {
                    responseType: 'blob'
                })
                .then(async response => {
                    const blob = new Blob([response.data], {type: 'application/pdf'});
                    const objectURL = URL.createObjectURL(blob);
                    await this.axios.patch('/Movimientos/' + this.mov_codigo + '/Impreso')
                    .then(() => {
                        window.open(objectURL, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
                        var index = this.$parent.movimientos.findIndex(x=>x.mov_codigo == this.mov_codigo);
                        if(index != -1)
                        {
                            this.$parent.movimientos[index].mov_impreso = 'S';
                        }
                    });                    
                });
                this.$root.stopLoading();
            },
            async imprimirFE()
            {
                this.$root.startLoading();
                await this.axios.get('/FacturacionElectronicaAPI/Imprimir/' + this.mov_mh_codigo_generacion, {
                    responseType: 'blob'
                })
                .then(async response => {
                    const blob = new Blob([response.data], {type: 'application/pdf'});
                    const objectURL = URL.createObjectURL(blob);
                    await this.axios.patch('/Movimientos/' + this.mov_codigo + '/Impreso')
                    .then(() => {
                        window.open(objectURL, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
                        var index = this.$parent.movimientos.findIndex(x=>x.mov_codigo == this.mov_codigo);
                        if(index != -1)
                        {
                            this.$parent.movimientos[index].mov_impreso = 'S';
                        }
                    });                    
                })
                .catch(() => {
                    M.toast({html: 'Ha ocurrido un error, intente nuevamente.'});
                });
                this.$root.stopLoading();
            },
            async cambiarCiclo(event)
            {
                if(this.detalle_movimiento.length > 0 && this.mov_codigo == 0)
                {
                    if(confirm('Si cambia el ciclo se eliminarán los aranceles agregados. ¿Desea continuar?'))
                    {
                        this.detalle_movimiento = [];
                        await this.setLog('Cambia el ciclo para el estudiante ' + this.estudiante.per_carnet + ' ('+this.estudiante.per_codigo+') a ' + this.mov_codcil);
                    }
                    else
                    {
                        if(event)
                        {
                            event.preventDefault();
                            event.stopPropagation();
                            return;
                        }
                    }
                }
                await this.getDatosEstudiante();
                this.current_tab = 1;
                this.movimientos_estudiante = [];
                this.exoneraciones = [];
                this.descuentos = [];
                this.dmo_codtmo = 0;
            },
            async anular()
            {
                if(!confirm('¿Desea anular el presente documento?')) return false;
                this.validations_cancel = [];
                var payload = {
                    mov_codigo: this.mov_codigo,
                    contrasena: this.contrasena,
                    mov_motivo_anulacion: this.mov_motivo_anulacion
                };
                this.$root.startLoading();
                await this.axios.patch('/Movimientos/' + this.mov_codigo + '/Anular', payload)
                .then(async ()=> {
                    this.mov_estado = 'A';
                    var index = this.$parent.movimientos.findIndex(x=>x.mov_codigo == this.mov_codigo);
                    if(index != -1)
                    {
                        this.$parent.movimientos[index].mov_estado = 'A';
                    }
                    await this.$parent.getMovimientos(this.$parent.current_page);
                    this.modal_autorizar.close();
                    this.contrasena = '';
                    M.toast({html: 'El documento ha sido anulado'});
                })
                .catch(error => {
                    var data = error.response.data;
                    if(error.response.status == 400 && error.response.data.type == 'M')
                    {
                        this.validations_cancel = error.response.data.modelErrors;
                    }
                    if(error.response.status == 400 && error.response.data.type == 'C')
                    {
                        this.contrasena = '';
                        M.toast({html: data.message});
                    }
                    if(error.response.status == 404)
                    {
                        M.toast({html: 'El documento no fué encontrado.'});
                    }
                });
                this.$root.stopLoading();
            },
            async getClientes(reload)
            {                
                if(this.clientes.length == 0 || (reload || false))
                {
                    this.$root.startLoading();
                    await this.axios.get('/Clientes/')
                    .then(response => {
                        this.clientes = response.data;
                    });
                    this.$root.stopLoading();
                }
            },
            async getCliente()
            {
                this.$root.startLoading();
                await this.axios.get('/Clientes/' + this.cardCode)
                .then(response => {
                    this.cliente = response.data;
                });
                this.$root.stopLoading();
            },            
            async setCliente()
            {
                var updated = await this.clienteActualizado();
                if(!updated)
                {
                    M.toast({html: 'Actualice primero los datos del cliente ' + this.mov_ClientCardCode});
                    setTimeout(() => {
                        window.open('/ClientesAdmin/' + this.mov_ClientCardCode);
                        this.mov_ClientCardCode = '';
                    }, 1000);
                    return;
                }
                this.mov_ClientCardName = '';
                this.NIT = '';
                this.NRC = '';
                this.Address = '';
                if(this.mov_ClientCardCode != '')
                {
                    this.cliente = this.clientes.find(x=>x.cardCode == this.mov_ClientCardCode);
                    this.mov_ClientCardName = this.cliente.cardName;
                    this.NIT = this.cliente.vatIdUnCmp;
                    this.NRC = this.cliente.addID;
                    this.Address = this.cliente.address;
                    await this.setLog('Selecciona cliente ' + this.mov_ClientCardCode + ' - ' + this.mov_ClientCardName + ' para el estudiante ' + this.estudiante.per_carnet + ' ('+this.estudiante.per_codigo+')');
                }
                this.modal_buscar_cliente.close();
            },
            async clienteActualizado()
            {
                var updated = false;
                this.$root.startLoading();
                await this.axios.get('/Clientes/' + this.mov_ClientCardCode + '/Actualizado')
                .then(response => {
                    if(response.status == 200)
                    {
                        updated = true;
                    }
                });
                this.$root.stopLoading();
                return updated;
            },
            mov_forma_pago_type_disabled(mov_forma_pago)
            {
                if(this.mov_tipo_pago == 'E' || this.mov_tipo_pago == 'T' || this.mov_tipo_pago == 'C')
                {
                    if(mov_forma_pago == 'C' || mov_forma_pago == 'P')
                    {
                        return false;
                    }
                }
                if(this.mov_tipo_pago == 'D' || this.mov_tipo_pago == 'R')
                {
                    if(mov_forma_pago == 'T' || mov_forma_pago == 'A' ||  mov_forma_pago == 'P')
                    {
                        return false;
                    }
                }
                return true;
            },
            mov_tipo_pago_type_disabled(mov_tipo_pago)
            {
                if(this.mov_tipo == 'C' || this.mov_tipo == 'F')
                {
                    if(mov_tipo_pago == 'E' || mov_tipo_pago == 'T' || mov_tipo_pago == 'C' || mov_tipo_pago == 'D')
                    {
                        return false;
                    }
                }
                if(this.mov_tipo == 'R')
                {
                    if(mov_tipo_pago == 'D' || mov_tipo_pago == 'R')
                    {
                        return false;
                    }
                }
                return true;
            },
            setMovTipoPago()
            {
                if(this.mov_tipo == 'C' || this.mov_tipo == 'F')
                {
                    this.mov_tipo_pago = 'E';
                    this.mov_forma_pago = 'C';
                    this.mov_fecha = new Date();
                }
                if(this.mov_tipo == 'R')
                {
                    this.mov_tipo_pago = 'D';
                    this.mov_forma_pago = 'A';
                }
                this.setBanco();
            },
            setMovFormaPago()
            {
                if(this.mov_tipo_pago == 'E' || this.mov_tipo_pago == 'T' || this.mov_tipo_pago == 'C')
                {
                    this.mov_forma_pago = 'C';
                }
                if(this.mov_tipo_pago == 'D' || this.mov_tipo_pago == 'R')
                {
                    this.mov_forma_pago = 'A';
                }
                this.setBanco();
            },
            async actualizarConcepto()
            {
                if(this.$isNullOrWhitespace(this.mov_descripcion_temp))
                {
                    M.toast({html: 'El concepto no puede estar vacío.'});
                    return false;
                }
                var data = {
                    mov_descripcion: this.mov_descripcion_temp
                };
                this.$root.startLoading();
                await this.axios.patch('/Movimientos/' + this.mov_codigo + '/Concepto', data)
                .then(async () => {
                    this.mov_descripcion = this.mov_descripcion_temp;
                    this.$root.stopLoading();
                    if(confirm('El concepto ha sido actualizado. ¿Desea imprimir el documento?'))
                    {
                        this.modal_concepto.close();
                        await this.imprimir();
                    }
                });                
            },
            async modalOpen(url)
            {
                this.$root.startLoading();
                 await this.axios.get(url, {
                    responseType: 'blob'
                })
                .then(async response => {
                    const blob = new Blob([response.data], {type: 'application/pdf'});
                    const objectURL = URL.createObjectURL(blob);
                    window.open(objectURL, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');                
                });
                this.$root.stopLoading();
            },
            async getNotas()
            {
                this.$root.startLoading();
                await this.axios.get('/NotasFacturacion/' + this.mov_codigo)
                .then(response => {
                    this.notas = response.data;
                });
                this.$root.stopLoading();
            },
            abrirNota(index)
            {
                if(index == this.nota_index)
                {
                    this.nota_index = -1;
                }
                else
                {
                    this.nota_index = index;
                }
            },
            async getNotaAdjunto(codnof)
            {
                this.$root.startLoading();
                await this.axios.get('/NotasFacturacion/' + codnof + '/Adjunto', {
                    responseType: 'blob'
                })
                .then(async response => {
                    const blob = new Blob([response.data], {type: 'application/pdf'});
                    const objectURL = URL.createObjectURL(blob);
                    window.open(objectURL, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');                
                })
                .catch(() => {
                    M.toast({html: 'El archivo no fue encontrado.'});
                });
                this.$root.stopLoading();
            },
            async detalleProcesoGraduacion()
            {
                this.proceso_graduacion = {};
                this.$root.startLoading();
                await this.axios.get('/Estudiantes/' + this.estudiante.per_codigo + '/' + this.mov_codcil + '/ProcesoGraduacion')
                .then(response => {
                    this.proceso_graduacion = response.data;
                })
                .catch(() => {
                    M.toast({html: 'No se pudo obtener la información, intente nuevamente.'});
                });
                this.$root.stopLoading();
            },
            async getDevoluciones()
            {
                //if(this.devoluciones_loaded) return;
                this.index_devolucion = -1;
                await this.axios.get('/Estudiantes/' + this.estudiante.per_codigo + '/Devoluciones')
                .then(response => {
                    this.devoluciones = response.data;
                    this.devoluciones_loaded = true;
                });
            },
            searchClienteModal()
            {
                this.search_cliente = '';
                this.modal_buscar_cliente = M.Modal.getInstance(document.querySelector('#modal-seleccionar-cliente'));
                this.modal_buscar_cliente.open();
                document.querySelector('#search_cliente').focus()
            },
            seleccionarCliente(id)
            {
                this.mov_ClientCardCode = id || '';
                this.setCliente();
                //this.modal_buscar_cliente.close();
            },
            async getHistorialSolvencia()
            {
                this.$root.startLoading();
                await this.axios.get('/Estudiantes/' + this.estudiante.per_codigo + '/Solvencia')
                .then(response => {
                    this.historial_solvencia = response.data;
                    for(let i = 0; i <= this.ciclos.length - 1; i++)
                    {
                        if(this.historial_solvencia.find(x=>x.cil_codcic == this.ciclos[i].cic_codigo_int && x.cil_anio == this.ciclos[i].cil_anio && x.cil_solvente) != undefined)
                        {
                            this.ciclos[i].cil_solvente = true;
                        }
                        else
                        {
                            this.ciclos[i].cil_solvente = false;
                        }
                    }
                });
                this.$root.stopLoading();
            },
            buscarArancel()
            {
                this.modal_buscar_arancel = M.Modal.getInstance(document.querySelector('#modal-buscar-arancel-alumno'));
                this.modal_buscar_arancel.open();
                document.querySelector('#txt_search_arancel').focus();
            },
            async seleccionarArancel(codtmo)
            {
                this.dmo_codtmo = codtmo;                
                this.modal_buscar_arancel.close();
                await this.getCostoArancel();
            },
            async getFechasPosgrado()
            {
                this.$root.startLoading();
                await this.axios.get('/Estudiantes/' + this.estudiante.per_codigo + '/FechasPosgrado/' + this.mov_codcil)
                .then(response => {
                    this.fechas_posgrado = response.data;
                });
                this.$root.startLoading();                
            },
            setBanco()
            {               
                if(this.mov_tipo_pago != 'D')
                {
                    this.mov_codban = 0;
                }
                else
                {
                    var banco = this.bancos.find(x=>x.ban_codigo != 0);
                    this.mov_codban = banco.ban_codigo;
                }
            },
            editarDetalle(index)
            {
                this.index_editando = index;
            },
            guardarEditarDetalle(index)
            {
                this.detalle_movimiento[index].dmo_descripcion = this.detalle_movimiento[index].dmo_descripcion.trim();
                var detalle = this.detalle_movimiento[index].dmo_descripcion;
                detalle = detalle.replaceAll(' ', '');
                if(detalle.length == 0)
                {
                    this.detalle_movimiento[index].dmo_descripcion = this.detalle_movimiento[index].tmo_descripcion;
                    this.index_editando = -1
                    return;
                }
                if(this.detalle_movimiento[index].dmo_descripcion.length < 5)
                {
                    M.toast({html: 'Debe agregar al menos 5 caracteres.'});
                    return;
                }
                if(this.detalle_movimiento[index].dmo_descripcion.length > 2000)
                {
                    M.toast({html: 'Agregue máximo 2000 caracteres. Ha agregado '+this.detalle_movimiento[index].dmo_descripcion.length+'.'});
                    return;
                }
                this.index_editando = -1
            },
            async setLog(detalle)
            {
                var data = {
                    lom_codigo: 0,
                    lom_codemp: 0,
                    lom_usuario: '',
                    lom_detalle: detalle
                };
                await this.axios.post('/Log', data)
                .then(() => {

                });
            }
        },
        computed: {
            per_estado()
            {
                var estado = 'A';
                switch(this.estudiante.per_estado)
                {
                    case 'A':
                        estado = 'Activo';
                        break;
                    case 'I':
                        estado = 'Inactivo';
                        break;
                    case 'E':
                        estado = 'Egresado';
                        break;
                    case 'G':
                        estado = 'Graduado';
                        break;
                    case 'P':
                        estado = 'Potencial';
                        break;
                }
                return estado;
            },
            per_tipo()
            {
                var tipo = 'Pregrado';
                switch(this.estudiante.per_tipo)
                {
                    case 'D':
                        tipo = 'Diplomado';
                        break;
                    case 'U':
                        tipo = 'Pregrado';
                        break;
                    case 'M':
                        tipo = 'Posgrado';
                        break;
                }
                return tipo;
            },
            car_virtual()
            {
                var modalidad = 'Presencial';
                switch(this.estudiante.car_virtual)
                {
                    case 'P':
                        modalidad = 'Presencial';
                        break;
                    case 'V':
                        modalidad = 'Virtual';
                        break;
                    default: 
                        modalidad = 'Presencial';
                }
                return modalidad;
            },
            tmo_afecta_materia()
            {
                if(this.dmo_codtmo == 0) return false;
                return this.aranceles.find(x=>x.tmo_codigo == this.dmo_codtmo).tmo_afecta_materia == 'S'; 
            },
            tipo_mov_arancel()
            {
                if(this.dmo_codtmo == 0) return false;
                var result = this.aranceles.find(x=>x.tmo_codigo == this.dmo_codtmo);
                return result.tmo_tipo_mov == 'C' || result.tmo_tipo_mov == 'M';
            },
            tmo_cantidad()
            {
                if(this.dmo_codtmo == 0) return false;
                return this.aranceles.find(x=>x.tmo_codigo == this.dmo_codtmo).tmo_cantidad == 'S'; 
            },
            tmo_talonarios()
            {
                if(this.dmo_codtmo == 0) return false;
                return this.aranceles.find(x=>x.tmo_codigo == this.dmo_codtmo).tmo_talonarios == 'S'; 
            },
            esEditable()
            {
                var es_admin = this.$store.state.roles.find(x=>x.rsa_codigo == 1) != undefined;
                var es_coordinador = this.$store.state.roles.find(x=>x.rsa_codigo == 3) != undefined;
                if(es_admin || es_coordinador) return true;
                if(this.mov_codigo > 0) return false;
                return true;
            },
            allowEdit()
            {
                var es_admin = this.$store.state.roles.find(x=>x.rsa_codigo == 1) != undefined;
                var es_coordinador = this.$store.state.roles.find(x=>x.rsa_codigo == 3) != undefined;
                if(es_admin || es_coordinador) return true;
                


                return true;
            },
            esActualizable()
            {
                if(this.mov_estado == 'A') return false;
                if(this.mov_codigo == 0) return false;
                var es_admin = this.$store.state.roles.find(x=>x.rsa_codigo == 1) != undefined;
                var es_coordinador = this.$store.state.roles.find(x=>x.rsa_codigo == 3) != undefined;
                if(es_admin || es_coordinador) return true;
                return false;
            },
            esAnulable()
            {
                //3 - Coordinador
                var coordinador = this.$store.state.roles.find(x=>x.rsa_codigo == 3 || x.rsa_codigo == 1) != undefined;
                if(this.mov_tipo == 'R') return false;
                if(this.mov_codigo == 0) return false;
                if(this.mov_exportado == 'S') return false;
                if(this.mov_estado == 'A') return false;
                if(this.mov_estado == 'G') return false;
                return coordinador;
            },
            total_iva()
            {
                return Math.round(this.detalle_movimiento.reduce((a, b) => a + b.dmo_iva, 0) * this.decimal_100) / this.decimal_100;
            },
            total_documento()
            {
                return Math.round(this.detalle_movimiento.reduce((a, b) => a + b.dmo_valor, 0) * this.decimal_100) / this.decimal_100;
            },
            mov_estado_descripcion()
            {
                if(this.mov_codigo == 0)
                    return "";
                var descripcion = "";
                switch(this.mov_estado)
                {
                    case 'R':
                        descripcion = 'REGISTRADO';
                        break;
                    case 'A':
                        descripcion = 'ANULADO';
                        break;
                    case 'G':
                        descripcion = 'GENERADO';
                        break;
                    case 'I':
                        descripcion = 'INACTIVO';
                        break;
                }
                return descripcion;
            },
            ver_costos()
            {
                if(this.dmo_codtmo == 0) return false;

                var arancel = this.aranceles.find(x=>x.tmo_codigo == this.dmo_codtmo);

                if(this.estudiante.tipo == 'D' && arancel.tmo_diplomado == 'S') return true;

                if(this.estudiante.tipo == 'P' && (arancel.tmo_tipo_mov == 'C' || arancel.tmo_tipo_mov == 'M')) return true;

                if(this.estudiante.tipo == 'M' && (arancel.tmo_tipo_mov == 'C' || arancel.tmo_tipo_mov == 'M')) return true;

                return false;
            },
            materias_autofinanciadas()
            {
                var index = this.autofinanciadas.findIndex(x=>x.codigoCicloPago == this.mov_codcil);
                if(index == -1) return [];
                return this.autofinanciadas[index].materias;
            },
            materias_practica_clinica()
            {
                if(this.materias_inscritas.length > 0)
                    return this.materias_inscritas.filter(x=>x.plm_horas_clinica > 0);
                return this.materias_plan.filter(x=>x.plm_horas_clinica > 0);
            },
            materias_laboratorio_enfermeria()
            {
                if(this.materias_inscritas.length > 0)
                    return this.materias_inscritas.filter(x=>x.plm_laboratorio == 'S');
                return this.materias_plan.filter(x=>x.plm_laboratorio == 'S');
            },
            clientes_filter()
            {
                return this.clientes.filter(x=>
                                                x.cardName.toLowerCase().indexOf(this.search_cliente.toLocaleLowerCase()) != -1 ||
                                                x.u_NDUI.toLowerCase().indexOf(this.search_cliente.toLocaleLowerCase()) != -1 
                                            );
            },
            aranceles_filter()
            {
                return this.aranceles.filter(x=>
                    x.tmo_descripcion.toLowerCase().indexOf(this.search_arancel.toLocaleLowerCase()) != -1
                );
            }
        }
    }
</script>

<style scoped>
    input[type=text]:disabled, textarea:disabled, select:disabled
    {
        color:#888
    }

    .mov_estado
    {
        top:0px;
        left:0px;
        position:absolute;
        padding:2px;
        background-color:#f6626e;
        font-size:11px;
        color:white
    }casa san miguelresid

    .excedente
    {
        padding:5px;
        background-color:green;
        color: white;
        position:absolute;
        left: 0px;
        top: 0px
    }

    .motivo-anulacion {
       /*  position:absolute; */
        padding: 5px;
        bottom: 0px;
        left: 0px;
        color: white;
        background-color: #b71c1c   
    }
</style>